import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Result from './presenter';
import { actionCreators } from '../ducks';
import { compose } from 'lodash/fp';
function mapStateToProps(state) {
    return {
        mode: state.headerSearchBar.mode,
        items: state.headerSearchBar.result,
        selectedItem: state.headerSearchBar.selectedItem,
        status: state.headerSearchBar.status,
        shouldShowLoader: state.headerSearchBar.shouldShowLoader,
        preventScroll: state.headerSearchBar.preventScroll,
    };
}
function mapDispatchToProps(dispatch, props) {
    const onBlur = x => {
        props.onBlur();
        return x;
    };
    return bindActionCreators({
        doHideResults: compose(onBlur, actionCreators.doHideResults),
        doUpdateQuery: actionCreators.doUpdateQuery,
        doSelectItem: actionCreators.doSelectItem,
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Result);
