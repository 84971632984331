import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import image from '../../../../../page/screens/registerPage/onboarding-survey-step-two-highlight.png';
import authorImage from '../../../../../page/screens/registerPage/tint-portrait-tim-sae-koo.jpg';
import { createOnboardingSettings, getCompanySizeFilled } from '../../../../api/custom/CompanyOnboarding';
import { Form } from '../../../components/Form';
import { FormErrorMessage } from '../../../components/Form/FormErrorMessage';
import { FormSelect } from '../../../components/Form/FormInputs';
import { FormItem } from '../../../components/Form/FormItem';
import RegisterFlowTemplate from '../../../components/RegisterFlowTemplate';
import SaveButton from '../../../components/SaveButton';
import { goToOnboardingSurveyInterestStep } from '../../../services/GoTo';
import { VALIDATORS } from '../../../services/Validation';
import styles from '../styles.m.less';
const BUTTON_LABELS = {
    DISABLED: 'Continue',
    ENABLED: 'Continue',
};
const ROLES = ['HR Admin', 'Executive', 'Manager', 'Founder'];
export default function OnboardingSurveyDemographicStep() {
    const [isCompanySizeFilled, setIsCompanySizeFilled] = useState(false);
    useEffect(() => {
        getCompanySizeFilled().then(setIsCompanySizeFilled);
    }, []);
    const handleSubmit = async (formValues) => {
        return createOnboardingSettings(formValues)
            .then(goToOnboardingSurveyInterestStep)
            .catch(goToOnboardingSurveyInterestStep);
    };
    return (_jsx(RegisterFlowTemplate, { step: "2", title: "Finish your profile setup", imageURL: image, quote: "\u201CIt's a really great tool because you have everything in one place. I kid you not, I thought it was the godsend answer.\u201D", author: "Tim Sae Koo", role: "CEO at Tint", authorImage: authorImage, children: _jsx(Form, { onSubmit: handleSubmit, formClassName: styles.registerFormClass, children: ({ submitError, invalid }) => (_jsxs("div", { className: styles.signUpIn, children: [_jsx(FormItem, { name: "role", label: "What's your role?", inputComponent: FormSelect, inputProps: {
                            placeholder: 'Choose a role',
                            menuClassName: styles.selectMenu,
                            options: ROLES,
                            optionToText: (v) => v,
                            optionToKey: (v) => v,
                            wrapperClassName: styles.inputWrapper,
                        }, validators: [VALIDATORS.required()] }), !isCompanySizeFilled && (_jsx(FormItem, { name: "companySize", label: "What is your company size?", type: "number", validators: [VALIDATORS.required()] })), _jsxs("div", { className: styles.formButtonContainer, children: [_jsx(SaveButton, { state: 'ENABLED', labels: BUTTON_LABELS, disabled: invalid, className: styles.register_form_button }), submitError ? _jsx(FormErrorMessage, { errorMessage: submitError }) : null] })] })) }) }));
}
