import { jsx as _jsx } from "react/jsx-runtime";
import Tabs from '../../../components/Tabs';
import { createQuery, TEAM, CYCLE } from '../../../services/HeaderSearchBar';
import { getCurrentUser } from '../../../services/CurrentUser';
const tabs = [
    {
        header: _jsx("span", { children: "Team" }),
        trackingLabel: 'team tab',
    },
    {
        header: _jsx("span", { children: "Cycles" }),
        trackingLabel: 'cycles tab',
    },
];
const IDS = {
    [TEAM]: 0,
    [CYCLE]: 1,
};
export default function Navigation({ mode, search, doSelectItem }) {
    const tabsProps = {
        tabs,
        onSelect: index => {
            doSelectItem(null);
            search(createQuery(getType(index), getCurrentUser()));
        },
        selectedIndex: getSelectedId(mode),
        eventTracking: { category: 'navigation header search bar', action: 'toggle' },
    };
    return _jsx(Tabs, Object.assign({ compact: true }, tabsProps));
}
function getType(index) {
    return [TEAM, CYCLE][index];
}
function getSelectedId(mode) {
    return IDS[mode];
}
