import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { compose, cond, get, getOr, map, overEvery, stubTrue } from 'lodash/fp';
import { descriptiveDate } from '../../../../common/services/date-helper/service';
import Link from '../../../components/Link';
import { getCurrentUser } from '../../../services/CurrentUser';
import { concatAnd } from '../../../services/Enumeration';
import { isFeatureEnabled } from '../../../services/Features';
import { asGenitive, asPossessivePronoun } from '../../../services/Gender';
import { getLink } from '../../../services/ProfileInfo';
import replacement from '../../../services/Replacement';
import r from '../../../services/Replacement';
import { ASSESSMENT_STATUS, REASONS_NOT_SHAREABLE, REASONS_NOT_SIGNABLE, REVIEW_STATUS, ASSESSMENT_TYPES, } from '../constants';
const getFirstName = get('firstName');
const getName = get('name');
const DEFAULT_GENDER = 'female';
const getGender = getOr(DEFAULT_GENDER, 'gender');
const getGenitive = compose(asGenitive, getFirstName);
const getPossessivePronoun = compose(asPossessivePronoun, getGender);
export const getGeneralWordings = (reviewee, mainReviewer, signedByReviewee, signedByMainReviewer) => ({
    title: `${getGenitive(reviewee)} ${r('pr.singular.title')}`,
    headerTitle: (_jsxs(_Fragment, { children: [_jsx(Link, { href: getLink(reviewee), openInNewTab: true, hasTextStyle: false, title: `Open ${getGenitive(reviewee)} profile in a new tab.`, children: getGenitive(reviewee) }), ' ', r('pr.singular.title')] })),
    selfAssessment: {
        title: r('pr.selfAssessment'),
        role: 'Reviewee',
        graphQuestion: 'Where do you see yourself?',
        graphTooltip: 'Drag and drop the dot to assess your contributions during the period under review.',
    },
    assessment: {
        title: getReviewerAssessmentWording(false),
        role: getReviewersWording(),
        graphQuestion: `Where do you see ${getFirstName(reviewee)}?`,
        graphTooltip: `Drag and drop the dot to assess ${getGenitive(reviewee)} contributions during the period under review.`,
    },
    soleSigneeActorFirstName: getSoleSigneeActorFirstName(reviewee, mainReviewer, signedByReviewee, signedByMainReviewer),
});
function getSoleSigneeActorFirstName(reviewee, mainReviewer, signedByReviewee, signedByMainReviewer) {
    if (signedByReviewee && !signedByMainReviewer) {
        return getFirstName(reviewee);
    }
    if (signedByMainReviewer && !signedByReviewee) {
        return mainReviewer ? getFirstName(mainReviewer) : `The ${r('pr.reviewer')}`;
    }
    return null;
}
const getRevieweeWordings = (generalWordings, mainReviewer, contributingReviewers) => ({
    title: `Your ${r('pr.singular.title')}`,
    headerTitle: `Your ${r('pr.singular.title')}`,
    otherRole: generalWordings.assessment.role,
    toWriteAssessmentGraph: generalWordings.selfAssessment.graphQuestion,
    toWriteAssessmentGraphTooltip: generalWordings.selfAssessment.graphTooltip,
    otherSideAssessmentGraph: generalWordings.assessment.graphQuestion,
    toWrite: r('pr.selfAssessment'),
    otherSide: r('pr.assessment'),
    otherSideMainActorName: mainReviewer ? getName(mainReviewer) : `The ${r('pr.reviewer')}`,
    otherSideMainActorFirstName: mainReviewer ? getFirstName(mainReviewer) : `The ${r('pr.reviewer')}`,
    otherSideActorsConcatenatedFirstNames: mainReviewer
        ? concatAnd(map(getFirstName, [mainReviewer, ...contributingReviewers]))
        : 'foo',
    otherSidePerspective: `${getPossessivePronoun(mainReviewer)} ${r('pr.assessment')}`,
    otherSideMainActorGender: getGender(mainReviewer),
    soleSigneeActorFirstName: generalWordings.soleSigneeActorFirstName,
});
const getReviewerWordings = (generalWordings, reviewee) => ({
    title: generalWordings.title,
    headerTitle: generalWordings.headerTitle,
    otherRole: generalWordings.selfAssessment.role,
    toWriteAssessmentGraph: generalWordings.assessment.graphQuestion,
    toWriteAssessmentGraphTooltip: generalWordings.assessment.graphTooltip,
    otherSideAssessmentGraph: generalWordings.selfAssessment.graphQuestion,
    toWrite: r('pr.assessment'),
    otherSide: r('pr.selfAssessment'),
    otherSideMainActorName: getName(reviewee),
    otherSideMainActorFirstName: getFirstName(reviewee),
    otherSideActorsConcatenatedFirstNames: concatAnd([getFirstName(reviewee)]),
    otherSidePerspective: `${getPossessivePronoun(reviewee)} ${r('pr.selfAssessment')}`,
    otherSideMainActorGender: reviewee.gender,
    soleSigneeActorFirstName: generalWordings.soleSigneeActorFirstName,
});
export const getWordings = ({ isReviewee, mainReviewer, reviewee: { user: reviewee }, contributingReviewers, signedByReviewee, signedByMainReviewer, }) => {
    const generalWordings = getGeneralWordings(reviewee, mainReviewer, signedByReviewee, signedByMainReviewer);
    return isReviewee
        ? getRevieweeWordings(generalWordings, mainReviewer, contributingReviewers)
        : getReviewerWordings(generalWordings, reviewee);
};
export const reasonNotShareableWording = (wordings, review, allRequiredAnswered) => {
    const res = [];
    switch (review.permissions.reasonNotShareable) {
        case REASONS_NOT_SHAREABLE.INDIRECT_MANAGERS_CANNOT_SHARE:
        case REASONS_NOT_SHAREABLE.NOT_MAIN_REVIEWER_AND_NO_SPECIAL_PERMISSION:
            res.push(`Only the Main ${r('pr.reviewer')} can share the ${wordings.toWrite}.`);
            break;
        case REASONS_NOT_SHAREABLE.BEFORE_SHARE_FROM_DATE:
            res.push(`Sharing will be possible starting on ${descriptiveDate(review.timeline.reviewerShareFrom, getCurrentUser().company.companySettings)}.`);
            break;
        case REASONS_NOT_SHAREABLE.EXCEEDED_CHARACTER_LIMIT:
            res.push(`You exceeded the character limit. Please shorten your answer(s) before sharing.`);
            break;
    }
    if (!allRequiredAnswered) {
        res.push(reasonNotShareableWordingUnanswered());
    }
    if (res.length === 0) {
        return null;
    }
    else {
        return res.join(' ');
    }
};
const reasonNotShareableWordingUnanswered = () => {
    return 'One or more mandatory questions have not been answered.';
};
export const reasonNotSignableWording = (wordings, review, allRequiredAnswered) => {
    const res = [];
    switch (review.permissions.reasonNotSignable) {
        case REASONS_NOT_SIGNABLE.ASSESSMENT_NOT_SHARED:
        case REASONS_NOT_SIGNABLE.SELF_ASSESSMENT_NOT_SHARED:
            res.push(`You cannot ${r('pr.sign')} until ${wordings.otherSideMainActorFirstName} shares ${wordings.otherSidePerspective}.`);
            break;
        case REASONS_NOT_SIGNABLE.INDIRECT_MANAGERS_CANNOT_SIGN:
            res.push(`Only the Main ${r('pr.reviewer')} can sign the ${wordings.toWrite}.`);
            break;
        case REASONS_NOT_SIGNABLE.EXCEEDED_CHARACTER_LIMIT:
            res.push(`You exceeded the character limit. Please shorten your answer(s) before signing.`);
            break;
    }
    if (!allRequiredAnswered) {
        res.push(reasonNotShareableWordingUnanswered());
    }
    if (res.length === 0) {
        return null;
    }
    else {
        return res.join(' ');
    }
};
export const answerNotVisibleWording = (review, user) => review.permissions.canViewOtherSide
    ? 'No answer given.'
    : {
        NO_MAIN_REVIEWER: 'No answer available',
        NOT_SHARED: 'No answer available',
        MUST_SHARE_TO_SEE: user && user.id !== getCurrentUser().id
            ? `${asGenitive(user.firstName)} answer will be visible after you share.`
            : 'No answer given.',
    }[review.permissions.reasonNotViewable];
export const attachmentsNotVisibleWording = (review, user) => review.permissions.canViewOtherSide
    ? 'No attachments uploaded.'
    : {
        NO_MAIN_REVIEWER: 'No attachments available',
        NOT_SHARED: 'No attachments available',
        MUST_SHARE_TO_SEE: user && user.id !== getCurrentUser().id
            ? `${asGenitive(user.firstName)} attachments will be available after you share.`
            : 'No attachments uploaded.',
    }[review.permissions.reasonNotViewable];
export function getReviewersWording(isPlural) {
    const hasCustomMainReviewers = isFeatureEnabled('FEATURE_changeMainReviewer');
    if (isPlural) {
        return r(hasCustomMainReviewers ? 'pr.reviewer.plural' : 'manager.plural.big');
    }
    return r(hasCustomMainReviewers ? 'pr.reviewer' : 'manager.big');
}
export function getReviewerAssessmentWording(isPlural) {
    const hasCustomMainReviewers = isFeatureEnabled('FEATURE_changeMainReviewer');
    if (isPlural) {
        return r(hasCustomMainReviewers ? 'pr.reviewerAssessment.plural' : 'pr.managerAssessment.plural');
    }
    return r(hasCustomMainReviewers ? 'pr.reviewerAssessment' : 'pr.managerAssessment');
}
export const getStartedWritingWarning = ({ reviewer, originalReviewer }) => {
    const reviewerWording = getReviewersWording(false);
    const reviewerAssessmentWording = getReviewerAssessmentWording(false);
    const hasStartedHint = `A previous ${reviewerWording} has already started writing the ${reviewerAssessmentWording}.`;
    const isNewReviewerDifferent = get('id', originalReviewer) !== get('id', reviewer);
    const takeOverHint = !!reviewer && isNewReviewerDifferent ? `${reviewer.name} will take over all existing content.` : '';
    return [hasStartedHint, takeOverHint].join(' ');
};
export const getEditLabel = (status, currentUserId, author, useIndividualAssessments, type = null, indirectManagerEdit = false) => {
    // Compatible with assessment and old review statuses for now.
    const isContributor = type === ASSESSMENT_TYPES.CONTRIBUTOR_ASSESSMENT;
    const EDIT_LABELS = {
        [ASSESSMENT_STATUS.NOT_STARTED]: 'Write',
        [REVIEW_STATUS.NOT_STARTED]: 'Write',
        [ASSESSMENT_STATUS.STARTED]: 'Continue Writing',
        [REVIEW_STATUS.STARTED]: 'Continue Writing',
    };
    const editLabel = isContributor ? 'Contribute' : EDIT_LABELS[status] || 'Edit';
    const showEditAs = useIndividualAssessments && !indirectManagerEdit && author && author.id !== currentUserId;
    if (showEditAs) {
        return `Edit as ${author.firstName || author.name}`;
    }
    return editLabel;
};
export const getDefaultRatingDescription = authorName => authorName ? `${asGenitive(authorName)} rating for this topic:` : 'Rating for this topic:';
export function getNoReviewerOnViewScreenWording(currentUser) {
    const defaultWording = `You do not have a ${r('pr.reviewer')} for this ${r('pr.singular')}. This can happen if you have no ${r('manager')}, and/or are at the top of the reporting chain.
    To access your ${r('pr.singular')}, please contact your ${r('general.hr')} admin to assign you a ${r('pr.reviewer')} for this Cycle.`;
    const adminWording = `No ${r('pr.reviewer')} assigned. To access this ${r('pr.singular')},
        please assign a temporary ${r('pr.reviewer')} for this Cycle.`;
    return currentUser.isAdmin ? adminWording : defaultWording;
}
export function getDefaultNoReviewerErrorWording() {
    return `Currently, you do not have a ${r('pr.reviewer')} assigned. To participate in this ${r('pr.singular')}
    Cycle, please contact your ${r('general.hr')} admin to assign you a ${r('pr.reviewer')} for this
    Cycle.`;
}
export function getNoReviewerOnPreviewQuestionnaireError() {
    return `No ${r('pr.reviewer')} assigned. This can happen if you have no ${r('manager')}, and/or are at the top of the reporting chain.
    To access this ${r('pr.singular')} preview, please assign a temporary ${r('pr.reviewer')} for yourself for this Cycle.`;
}
export const reviewerWording = getReviewersWording(false);
export function getReviewerTooltip(code, name, isSharingWithReviewee) {
    const reviewerTitles = generateReviewerTitles(name);
    if (isEmptyCode(code)) {
        return reviewerTitles.noRating;
    }
    if (isSharingWithReviewee) {
        return reviewerTitles.rating;
    }
    return reviewerTitles.ratingNotShared;
}
export function getRevieweeTooltip(code, name) {
    const revieweeTitles = generateRevieweeTitles(name);
    if (isEmptyCode(code)) {
        return revieweeTitles.noRating;
    }
    return revieweeTitles.rating;
}
export function isEmptyCode(code) {
    return code === '-';
}
export function generateReviewerTitles(name) {
    return {
        noRating: `No ${reviewerWording} ${r('pr.rating')}`,
        rating: `${r('pr.rating')} by ${reviewerWording}: ${name}`,
        ratingNotShared: `${r('pr.rating')} by ${reviewerWording} (not shared): \n${name}`,
    };
}
export function generateRevieweeTitles(name) {
    return {
        noRating: `No ${r('pr.selfRating')} yet, or not shared`,
        rating: `${r('pr.selfRating')} by employee: ` + name,
    };
}
const isSelfAssessment = assessment => assessment.type === 'SELF_ASSESSMENT';
const isShared = assessment => assessment.assessmentStatus === 'SHARED';
export function getSaveAndCloseText(forAssessment) {
    return cond([
        [overEvery([isSelfAssessment, isShared]), () => replacement('pr.postShared.reviewee.saveAndClose')],
        [isSelfAssessment, () => replacement('pr.preShared.reviewee.saveAndClose')],
        [stubTrue, () => 'Save and Close'],
    ])(forAssessment);
}
export function getShareText(forAssessment) {
    return isSelfAssessment(forAssessment) ? replacement('pr.preShared.reviewee.share') : 'Share';
}
