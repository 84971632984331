import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import classNames from 'classnames';
import styles from './style.m.less';
import Button from '../Button';
import { scrollToAnimated } from '../../services/Scroll';
import { IconArrowUp } from 'featherico';
const HEADER_OFFSET = 50;
export default class ToTopButton extends Component {
    render() {
        const { top, show, offset, onClick } = this.props;
        const classes = classNames(styles.button, { [styles.visible]: show });
        const onToTop = event => {
            const yPosition = (top || 0) - offset - HEADER_OFFSET;
            scrollToAnimated(window.pageXOffset, yPosition, 0);
            onClick(event);
        };
        return (_jsxs(Button, { kind: "PLAIN", className: classes, onClick: onToTop, children: [_jsx(IconArrowUp, {}), " To Top"] }));
    }
}
ToTopButton.defaultProps = {
    onClick: () => { },
    offset: 0,
};
