import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconStar } from 'featherico';
import Button from '../../components/Button';
import styles from './styles.m.less';
const G2Link = 'https://www.g2.com/products/small-improvements/reviews/start?return_to=https%3A%2F%2Fwww.g2.com%2Fproducts%2Fsmall-improvements%2Ftake_survey';
function G2Widget() {
    const renderStars = () => {
        const starElements = Array.from({ length: 5 }, (_, index) => (_jsx("div", { children: _jsx(IconStar, { className: styles.reviewStar }) }, index)));
        return _jsx("div", { className: styles.starContainer, children: starElements });
    };
    const clickHandler = () => {
        window.open(G2Link, '_blank', 'noopener');
    };
    return (_jsxs("div", { className: styles.container, children: [renderStars(), _jsx(Button, { kind: "LINK", className: styles.link, onClick: clickHandler, eventTracking: { category: 'navigation footer', action: 'write G2 review' }, children: "Write a review on G2" })] }));
}
export default G2Widget;
