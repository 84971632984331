// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".G2Widget-container-2EBMC {\n  border-radius: 4px;\n  background-color: #ffd63363;\n  box-shadow: 0 4px 4px 0 #a3a2a130;\n  padding: 10px;\n  width: 150px;\n  margin-top: 40px;\n}\n.G2Widget-starContainer-SRVI7 {\n  display: flex;\n  justify-content: space-between;\n  position: relative;\n  margin-bottom: 4px;\n  margin-top: 2px;\n}\n.G2Widget-reviewStar-af1Dj {\n  color: #ffcc00;\n  fill: #ffcc00;\n  height: auto !important;\n  width: auto !important;\n}\n.G2Widget-link-19EM7 {\n  color: #606060 !important;\n  font-size: 13px;\n  font-weight: 500;\n  justify-content: flex-end;\n  flex-direction: column;\n  --fi-size: 14px;\n  --fi-stroke: var(--fi-stroke-m);\n}\n", ""]);
// Exports
exports.locals = {
	"container": "G2Widget-container-2EBMC",
	"starContainer": "G2Widget-starContainer-SRVI7",
	"reviewStar": "G2Widget-reviewStar-af1Dj",
	"link": "G2Widget-link-19EM7"
};
module.exports = exports;
