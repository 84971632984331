import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import PropTypes from 'prop-types';
import history from './history';
class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            error: null,
        };
    }
    componentDidCatch(error) {
        this.setState({ error });
    }
    componentDidMount() {
        this.onUnmount = history === null || history === void 0 ? void 0 : history.listen(() => {
            if (this.state.error) {
                this.setState({ error: null });
            }
        });
    }
    componentWillUnmount() {
        var _a;
        (_a = this.onUnmount) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    render() {
        const { errorComponent: ErrorComponent } = this.props;
        if (this.state.error) {
            return _jsx(ErrorComponent, { error: this.state.error });
        }
        return this.props.children;
    }
}
ErrorBoundary.propTypes = {
    errorComponent: PropTypes.func,
};
export default ErrorBoundary;
