import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import replacement from '../../../../services/Replacement';
import Link from '../../../../components/Link';
const Logo = ({ user }) => {
    const { companyLogoId, companyLogoHeight } = user.company.companyDesignSettings;
    const customLogoHeight = Math.round(30 + companyLogoHeight * 0.2) + 'px';
    const customLogoSrc = companyLogoId && `/imageservice?id=${companyLogoId}&crop=false&size=-1`;
    const customLogoAlt = user.company.name;
    const hasCustomCompanyName = replacement('header.companyName') !== 'Small Improvements';
    return (_jsxs(Link, { className: "app-header-logo", href: "/app/home", "aria-label": "Small Improvements Home", eventTracking: { category: 'navigation header', action: 'click logo' }, children: [!customLogoSrc && hasCustomCompanyName && (_jsxs("div", { className: "app-header-button-content", children: [_jsx("img", { className: "app-header-button-content-default", src: "/images/logo/2020/si-logomark-color.svg", alt: "" }), _jsx("div", { className: "app-header-button-label app-header-item-label", children: replacement('header.companyName') })] })), !customLogoSrc && !hasCustomCompanyName && (_jsxs("div", { className: "app-header-button-content app-header-logo-logo-only", children: [_jsx("img", { className: "app-header-button-content-default app-header-logo-logo-only-icon", src: "/images/logo/2020/si-logomark-color.svg", alt: "" }), _jsx("img", { className: "app-header-button-content-default app-header-logo-logo-only-full", src: "/images/logo/2020/si-logo-1line-color.svg", alt: "" })] })), customLogoSrc && (_jsx("img", { alt: customLogoAlt, className: "app-header-logo-custom", src: customLogoSrc, style: { maxHeight: customLogoHeight } }))] }));
};
export default Logo;
