import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { compose } from 'lodash/fp';
import image from '../../../../../page/screens/registerPage/onboarding-survey-step-two-highlight.png';
import authorImage from '../../../../../page/screens/registerPage/zapier-portrait.jpg';
import api from '../../../../api';
import { updateOnboardingSettings } from '../../../../api/custom/CompanyOnboarding';
import Button from '../../../components/Button';
import { Form } from '../../../components/Form';
import { FormErrorMessage } from '../../../components/Form/FormErrorMessage';
import { FormGridRadio } from '../../../components/Form/FormInputs';
import { FormItem } from '../../../components/Form/FormItem';
import RegisterFlowTemplate from '../../../components/RegisterFlowTemplate';
import SaveButton from '../../../components/SaveButton';
import { withAppContext } from '../../../hoc/withAppContext';
import { withPageData } from '../../../hoc/withPageData';
import { goToDashboardPage } from '../../../services/GoTo';
import styles from '../styles.m.less';
const BUTTON_LABELS = {
    DISABLED: 'Go to Homepage',
    ENABLED: 'Go to Homepage',
};
function OnboardingSurveyHris({ adminLinks }) {
    const handleSubmit = async (formValue) => {
        if (formValue.hris) {
            return updateOnboardingSettings({ hris: formValue.hris.name })
                .then(goToDashboardPage)
                .catch(goToDashboardPage);
        }
    };
    const getIconImages = () => {
        let iconImagesArray = {};
        adminLinks.forEach((option) => {
            let icon;
            try {
                icon = require(`../../../features/Admin/Overview/svg/${option.icon}.svg`);
            }
            catch (e) {
                icon = require(`../../../features/Admin/Overview/svg/default.svg`);
            }
            iconImagesArray = Object.assign(Object.assign({}, iconImagesArray), { [option.icon]: icon });
        });
        return iconImagesArray;
    };
    return (_jsx(RegisterFlowTemplate, { step: "4", imageURL: image, quote: "Small Improvements supports our remote working culture because it\u2019s accessible from anywhere, intuitive to use, and easily adaptable to what we need.", author: "Jenny Bloom", role: "Chief Financial Officer, Zappier", authorImage: authorImage, title: "Integrate with your HRIS", children: _jsx(Form, { onSubmit: handleSubmit, formClassName: styles.registerFormClass, children: ({ submitError, invalid, pristine }) => {
                return (_jsxs("div", { className: styles.signUpIn, children: [_jsx(FormItem, { name: "hris", label: "", inputComponent: FormGridRadio, inputProps: {
                                options: adminLinks,
                                urlMapping: getIconImages(),
                            } }), _jsxs("div", { className: styles.formButtonContainer, children: [_jsx(SaveButton, { state: 'ENABLED', labels: BUTTON_LABELS, disabled: invalid || pristine, className: styles.register_form_button }), submitError ? _jsx(FormErrorMessage, { errorMessage: submitError }) : null, _jsx(Button, { kind: "LINK", onClick: () => handleSubmit({ hris: { name: 'other' } }), children: "My HRIS is not listed" })] })] }));
            } }) }));
}
export default compose(withPageData({
    resolve: {
        adminLinks: () => api.admin
            .getIntegrationsLinks()
            .then(data => data.filter((option) => option.category.includes('Automated User Import'))),
    },
}), withAppContext)(OnboardingSurveyHris);
