import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { map } from 'lodash/fp';
import { Fragment } from 'react';
import { trackEvent } from '../../../react/services/Tracking';
import styles from './style.m.less';
export const VerticalRadio = ({ value, options, onChange, className, small = true, eventTracking }) => {
    return (_jsx("div", { className: classNames(className, styles.verticalRadio, { small }), children: map(option => {
            const id = `${option.value}`;
            return (_jsxs(Fragment, { children: [_jsx("input", { id: id, name: option.value, disabled: option.disabled, type: "radio", checked: value === option.value, onChange: () => {
                            if (eventTracking) {
                                trackEvent(Object.assign(Object.assign({}, eventTracking), { label: option.trackingLabel }));
                            }
                            return option.value !== value ? onChange(option.value) : null;
                        } }), _jsx("label", { htmlFor: id, title: option.title, className: classNames({ [styles.smallLabel]: small, [styles.regularLabel]: !small }), children: option.label })] }, option.value));
        }, options) }));
};
