import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Component } from 'react';
import { throttle } from 'lodash';
import ToTopButton from '../ToTopButton';
import { topPosition, normalizedScrollTop, hasReachedScrollEnd } from './service';
const propTypes = {
    initialCount: PropTypes.number, // initial number of items to render
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    increment: PropTypes.func, // (count) => incrementedCount
    onItemsDepleted: PropTypes.func, // (numberOfItems, countToRender) => void
    children: PropTypes.func, // (itemsToRender) => children
    scrollThreshold: PropTypes.number,
    hasMore: PropTypes.func, // (count) => bool
    pending: PropTypes.bool,
    closer: PropTypes.element,
    loader: PropTypes.element,
    noToTop: PropTypes.bool,
};
const SCROLL_UP_THRESHOLD = 150;
export default class InfiniteScroll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: props.initialCount,
            depleted: false,
            finito: false,
            showToTopButton: false,
        };
        this.scrollListener = throttle(this.scrollListener.bind(this), 100);
        this.scrollPosition = 0;
        this.node = null;
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.items !== this.props.items) {
            const finito = newProps.items.length === this.props.items.length;
            this.setState({ count: newProps.items.length, depleted: false, finito });
        }
    }
    componentDidMount() {
        this.attachScrollListener();
    }
    componentDidUpdate() {
        this.attachScrollListener();
    }
    componentWillUnmount() {
        this.detachScrollListener();
    }
    attachScrollListener() {
        const hasMore = this.props.hasMore(this.state.count);
        if (this.state.depleted || !hasMore || this.isListening || this.state.finito) {
            return;
        }
        this.scrollListener();
        window.addEventListener('scroll', this.scrollListener);
        window.addEventListener('resize', this.scrollListener);
        this.isListening = true;
    }
    detachScrollListener() {
        this.isListening = false;
        window.removeEventListener('scroll', this.scrollListener);
        window.removeEventListener('resize', this.scrollListener);
    }
    scrollListener() {
        this.checkForScrollButton();
        if (this.hasReachedScrollEnd()) {
            this.detachScrollListener();
            const { items, onItemsDepleted, pending } = this.props;
            let { count } = this.state;
            let depleted = false;
            if (items.length > count) {
                count = this.props.increment(count);
            }
            if (count >= items.length) {
                if (onItemsDepleted) {
                    if (!pending) {
                        onItemsDepleted(items.length, count);
                    }
                }
                else {
                    depleted = true;
                }
            }
            this.setState({ count, depleted });
        }
    }
    hasReachedScrollEnd() {
        return hasReachedScrollEnd(this.node, this.props.scrollThreshold);
    }
    checkForScrollButton() {
        const nextScrollPosition = normalizedScrollTop();
        if (nextScrollPosition < this.scrollPosition) {
            this.scrollUpDistance += this.scrollPosition - nextScrollPosition;
            const elementTopPosition = topPosition(this.node);
            const scrollDistance = nextScrollPosition - elementTopPosition;
            let showToTopButton = scrollDistance > this.props.toTopButtonThreshold && this.scrollUpDistance > SCROLL_UP_THRESHOLD;
            const update = { showToTopButton };
            if (showToTopButton) {
                update.top = elementTopPosition;
            }
            this.setState(update);
        }
        else {
            this.setState({ showToTopButton: false });
            this.scrollUpDistance = 0;
        }
        this.scrollPosition = nextScrollPosition;
    }
    render() {
        const { count, finito, showToTopButton, top } = this.state;
        const { items, children: renderItems, loader, closer, pending, className, noToTop } = this.props;
        const itemsToRender = items.slice(0, count);
        const children = renderItems(itemsToRender);
        const onScrollTop = () => this.setState({ showToTopButton: false });
        return (_jsxs("div", { ref: node => (this.node = node), className: className, children: [!noToTop ? _jsx(ToTopButton, { show: showToTopButton, onClick: onScrollTop, top: top }) : null, children, finito && closer, !finito && pending && loader] }));
    }
}
InfiniteScroll.defaultProps = {
    initialCount: 20,
    increment: count => count + 20,
    onItemsDepleted: null,
    scrollThreshold: 250,
    hasMore: () => true,
    pending: false,
    toTopButtonThreshold: 900,
    noToTop: false,
};
InfiniteScroll.propTypes = propTypes;
