import { easeInOutQuad } from '../Easings';
import domScrollIntoView from 'dom-scroll-into-view';
const FPS = 60;
const FRAME_TIME = 1000 / FPS;
export function scrollToAnimated(x, y, duration = 1500, callback = () => { }) {
    let current = 0;
    let increment = 20;
    animate();
    function animate() {
        current += increment;
        const start = currentPos();
        const change = {
            x: x - start.x,
            y: y - start.y,
        };
        const newVals = {
            x: easeInOutQuad(current, start.x, change.x, duration),
            y: easeInOutQuad(current, start.y, change.y, duration),
        };
        window.scrollTo(newVals.x, newVals.y);
        if (current < duration) {
            window.requestAnimationFrame(animate);
        }
        else {
            callback();
        }
    }
    function currentPos() {
        return { x: window.scrollX, y: window.scrollY };
    }
}
export function scrollTo(element, to, duration = 120) {
    // jump to target if duration zero
    if (duration <= 0) {
        element.scrollTop = to;
        return;
    }
    let current = 0;
    const startPosition = element.scrollTop;
    let prevTimestamp;
    const animate = timestamp => {
        const factor = prevTimestamp ? (timestamp - prevTimestamp) / FRAME_TIME : 1;
        current += 10 * factor;
        current = Math.min(current, duration);
        prevTimestamp = timestamp;
        const difference = to - startPosition;
        const nextPosition = easeInOutQuad(current, startPosition, difference, duration).toFixed(2);
        if (nextPosition < to) {
            element.scrollTop = nextPosition;
            window.requestAnimationFrame(animate);
        }
        else {
            element.scrollTop = to;
        }
    };
    window.requestAnimationFrame(animate);
}
export function scrollIntoViewIfNeeded(container, element) {
    return domScrollIntoView(element, container, {
        onlyScrollIfNeeded: true,
    });
}
