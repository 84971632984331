import { get, startsWith } from 'lodash/fp';
import { getCurrentUser } from '../CurrentUser';
import loadScript from '../loadScript';
export const GOOGLE_ANALYTICS_TRACKING_CODE = 'UA-17919853-3';
export function isTrackingEnabled() {
    const currentUser = getCurrentUser();
    const isInProduction = startsWith('prod', get('company.server', currentUser));
    return (get('allowGoogleAnalytics', currentUser) &&
        isInProduction &&
        !currentUser.company.companySettings.optOutExternalServicesDataSync);
}
let gaPromise;
export async function getTracking() {
    if (!isTrackingEnabled()) {
        throw new Error('Tracking is disabled');
    }
    if (!gaPromise) {
        gaPromise = loadTracking();
    }
    return gaPromise;
}
async function loadTracking() {
    await Promise.all([
        loadScript('https://www.google-analytics.com/analytics.js'),
        import(/* webpackChunkName: "tracking" */ 'autotrack/lib/plugins/clean-url-tracker'),
        import(/* webpackChunkName: "tracking" */ 'autotrack/lib/plugins/outbound-link-tracker'),
        import(/* webpackChunkName: "tracking" */ 'autotrack/lib/plugins/page-visibility-tracker'),
        import(/* webpackChunkName: "tracking" */ 'autotrack/lib/plugins/url-change-tracker'),
    ]);
    const { ga } = window;
    const companyId = getCurrentUser().company.id;
    const userId = getCurrentUser().id;
    ga('create', GOOGLE_ANALYTICS_TRACKING_CODE, 'auto');
    ga('require', 'cleanUrlTracker');
    ga('require', 'outboundLinkTracker');
    ga('require', 'pageVisibilityTracker');
    ga('require', 'urlChangeTracker');
    ga('set', 'allowAdFeatures', false);
    ga('set', 'anonymizeIp', true);
    ga('set', 'dimension1', companyId);
    ga('set', 'userId', userId);
    ga('send', 'pageview');
    return ga;
}
const CATEGORIES_NAMES = {
    REVIEWS_V2: 'Reviews v2',
    DASHBOARD: 'Dashboard',
    REQUEST_FEEDBACK: 'Request Feedback',
    USER_PROFILE: 'User Profile',
    USER_PROFILE_V2: 'User Profile v2',
    USER_PICKER: 'User Picker',
    COMPANY_DIRECTORY: 'Company Directory',
    SLACK_INTEGRATION: 'Slack Integration',
    SYSTEM: 'System',
    MEETINGS_CALENDAR_INTEGRATION: 'Meetings Calendar Integration',
    REVIEW_FOLLOW_UP_MEETING: 'Review Follow-Up Meeting',
    MEETINGS: 'Meetings',
    INVITE_USERS_MODAL: 'Invite Users Modal',
    INVITE_USERS_ONBOARDING_MODAL: 'Invite Users Onboarding Modal',
    SKIP_INVITE_USERS_ONBOARDING_MODAL: 'Skip Add Teammates Onboarding',
    HELPFUL_RESOURCES_ATTACHMENTS: 'Helpful Resources Attachments',
    '360_PROVIDE_SCREEN_V2': '360 writing experience v2',
    ENGAGEMENT_ADDON_TRIAL: 'Engagement Addon Trial Page',
    MESSAGE_STATISTICS: 'Message Statistics',
    AI_WRITING_ASSISTANT: 'AI Writing Assistant',
    NAVATTIC_TUTORIAL: 'Navattic tutorial',
};
export const LABEL_PREFIXES = {
    '360_FEEDBACK_REQUEST_ID': '360 feedback requestId',
    '360_CYCLE_ID': '360 cycle id',
    USER_ID: 'user',
    FEEDBACK_REQUEST_ID: 'feedback request',
    FEEDBACK_PARTICIPANT_ID: 'participant id',
    REVIEW_CYCLE_ID: 'review cycle',
    REVIEW_ID: 'review',
    SURVEY_ID: 'survey',
    SURVEY_INSTANCE_ID: 'survey instance',
    ASSESSMENT_STATUS: 'reviewer assessment status',
};
/**
 * Track custom events via Google Analytics.
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/events
 */
export async function trackEvent({ category, action = 'click', label, value, nonInteractive }) {
    let eventCategory = category;
    if (category in CATEGORIES_NAMES) {
        // @ts-ignore
        eventCategory = CATEGORIES_NAMES[category];
    }
    const roundedValue = value !== undefined ? Math.round(value) : value;
    if (__DEV__) {
        /* eslint-disable no-console */
        if (!Number.isInteger(value !== null && value !== void 0 ? value : 0)) {
            console.error(`[GA] value is ${value} but must be an integer! Non-integer values will be rounded to the nearest integer.`);
        }
        console.groupCollapsed('[GA] track event:', [eventCategory, action, label].filter(v => v !== undefined).join(' > '));
        console.table({
            category: eventCategory,
            action,
            label,
            value: roundedValue,
        });
        console.groupEnd();
        /* eslint-enable no-console */
    }
    if (!isTrackingEnabled()) {
        return;
    }
    try {
        const ga = await getTracking();
        ga('send', {
            hitType: 'event',
            eventCategory,
            eventAction: action,
            eventLabel: label,
            eventValue: roundedValue,
            nonInteractive,
        });
    }
    catch (e) {
        // don't handle this exception
    }
}
