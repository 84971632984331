import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { compose, setPropTypes } from 'recompose';
import { API_URL } from '../../../../../../app-config/constants';
import api from '../../../../../api';
import { get } from '../../../../../api/request';
import LoadingIndicatorFull from '../../../../components/LoadingIndicatorFull';
import { withData } from '../../../../hoc/withData';
import { EMAIL_TEMPLATE_TYPES } from '../../constants/email-templates';
import ReviewCycleCreate from '../../lazy';
import { getCopyModel } from '../../services';
function ReviewCycleCopyPage({ cycle, users, emailTemplates }) {
    const { model: backendModel } = cycle;
    const model = getCopyModel(backendModel, users, emailTemplates);
    return _jsx(ReviewCycleCreate, { mode: "copy", model: model });
}
const BASE = `${API_URL}/review-cycles/guided`;
export default compose(setPropTypes({
    cycleId: PropTypes.string.isRequired,
}), withData({
    resolve: {
        cycle: ({ cycleId }) => get(`${BASE}/${cycleId}/copy`),
        users: () => api.usersMedium.getAll(),
        emailTemplates: ({ cycleId }) => api.emailTemplate.getTemplates(EMAIL_TEMPLATE_TYPES, { reviewCycleId: cycleId }),
    },
    pendingComponent: () => _jsx(LoadingIndicatorFull, {}),
}))(ReviewCycleCopyPage);
