import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TooltipInfoIcon from '../../TooltipInfoIcon';
import styles from './style.m.less';
export const FormLabel = ({ className, children, subLabel, htmlFor, info }) => (_jsxs("label", { htmlFor: htmlFor, className: classNames(styles.label, className), children: [children, " ", subLabel && _jsx("span", { className: styles.subLabel, children: subLabel }), info && _jsx(TooltipInfoIcon, { tooltip: info })] }));
FormLabel.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    subLabel: PropTypes.node,
    htmlFor: PropTypes.string,
    info: PropTypes.string,
};
