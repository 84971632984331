import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { mapProps } from '../../../hoc/recompose';
import r from '../../../services/Replacement';
import EntityPreview from '../EntityPreview';
import { ObjectiveIcon } from '../icons';
import PopupAlertContent from '../PopupAlertContent';
export const ObjectiveCreatedAlert = mapProps(props => (Object.assign(Object.assign({}, props), { action: 'Created' })))(ObjectiveAlert);
export const ObjectiveCopiedAlert = mapProps(props => (Object.assign(Object.assign({}, props), { action: 'Copied' })))(ObjectiveAlert);
export const ObjectiveMovedAlert = mapProps(props => (Object.assign(Object.assign({}, props), { action: 'Moved', showView: false })))(ObjectiveAlert);
function ObjectiveAlert({ objective, action, showView = true }) {
    const title = `${r('objective.singular.big')} ${action}`;
    const href = showView ? `/app/objectives/${objective.cycleId}/${objective.id}` : undefined;
    return (_jsx(PopupAlertContent, { title: title, href: href, icon: _jsx(ObjectiveIcon, { objective: objective }), children: _jsx(EntityPreview, { title: objective.title, subtitle: objective.cycle.name }) }));
}
ObjectiveAlert.propTypes = {
    objective: PropTypes.shape({
        title: PropTypes.string.isRequired,
        cycle: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        icon: PropTypes.string.isRequired,
    }).isRequired,
    action: PropTypes.string.isRequired,
    showView: PropTypes.bool,
};
