import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import axios from 'axios';
import { IconAlertTriangle } from 'featherico';
import { some } from 'lodash/fp';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { API_URL } from '../../../../app-config/constants';
import { logout } from '../../../api/custom/UserLogout';
import { schedulePageReload } from '../../../routing/history';
import Button from '../../components/Button';
import { useAppContext } from '../../hooks/useAppContext';
import useEventBus, { EventType } from '../../hooks/useEventBus';
import { goToUrl } from '../../services/Router';
import { trackEvent } from '../../services/Tracking';
import UserLoggedOutModal from './UserLoggedOutModal';
const MINUTE = 60000;
const DAY = MINUTE * 60 * 24;
const CHECK_PERIOD = MINUTE * 5;
const OUTDATED_VERSION_WARNING_DELAY = DAY * 3;
const WarningBanner = ({ children, show, gaLabel }) => {
    useEffect(() => {
        if (show) {
            trackEvent({
                category: 'SYSTEM',
                label: gaLabel,
                nonInteractive: true,
            });
        }
    }, [show, gaLabel]);
    if (!show) {
        return null;
    }
    return (_jsx("div", { className: "boxWarning broadcast", children: _jsxs("div", { children: [_jsx(IconAlertTriangle, {}), " ", children] }) }));
};
const OutdatedVersionBanner = ({ show }) => (_jsxs(WarningBanner, { show: show, gaLabel: 'Show "outdated version" banner', children: ["Your version of Small Improvements is outdated. Please", ' ', _jsx(Button, { kind: "LINK", onClick: () => window.location.reload(), children: "refresh this page" }), ' ', "to see the newest version."] }));
const LoggedOutBanner = ({ show }) => (_jsxs(WarningBanner, { show: show, gaLabel: 'Show "logged out" banner', children: ["You\u2019ve been logged out. Please make a copy of unsaved content before you leave this page or", ' ', _jsx("a", { href: "/login", children: "log in again" }), "."] }));
const PermissionsChangedBanner = ({ show }) => (_jsxs(WarningBanner, { show: show, gaLabel: 'Show "permissions changed" banner', children: ["Your permissions within Small Improvements have changed. You need to", ' ', _jsx(Button, { onClick: () => logout().then(() => goToUrl('/loggedout')), kind: "LINK", children: "logout" }), ' ', "and log in again to apply the new permissions."] }));
const useSystemStatus = () => {
    const { user } = useAppContext();
    const [state, setState] = useState({
        isLoggedIn: true,
        permissionsChanged: false,
        serverRev: __currentRev__,
        recievedUnauthorizedApiResponse: false,
    });
    useEventBus(EventType.UnauthorizedApiResponse, () => {
        setState(Object.assign(Object.assign({}, state), { recievedUnauthorizedApiResponse: true }));
    });
    useEffect(() => {
        let isMounted = true;
        let isFetching = false;
        let timeoutId;
        const isVisible = () => document.visibilityState === 'visible';
        const handleSystemStatus = (status) => {
            if (!isMounted) {
                return;
            }
            if (!status) {
                return setState({
                    isLoggedIn: false,
                    serverRev: __currentRev__,
                    permissionsChanged: false,
                    recievedUnauthorizedApiResponse: true,
                });
            }
            setState({
                isLoggedIn: true,
                serverRev: status.serverRev || __currentRev__,
                permissionsChanged: some(Boolean, [
                    status.roles.isHR !== user.isHR,
                    status.roles.isAssistant !== user.isAssistant,
                    status.roles.isAdmin !== user.isAdmin,
                ]),
                recievedUnauthorizedApiResponse: false,
            });
        };
        const fetchSystemStatus = async () => {
            isFetching = true;
            try {
                const { data: status } = await axios.get(`${API_URL}/users/${user.id}/isLoggedIn`);
                handleSystemStatus(status);
            }
            catch (_a) {
                // No need to handle network or server errors.
            }
            finally {
                isFetching = false;
                if (isVisible() && isMounted) {
                    timeoutId = window.setTimeout(fetchSystemStatus, CHECK_PERIOD);
                }
            }
        };
        const handleStatusChange = () => {
            window.clearTimeout(timeoutId);
            if (isVisible() && !isFetching) {
                fetchSystemStatus();
            }
        };
        document.addEventListener('visibilitychange', handleStatusChange);
        if (isVisible()) {
            timeoutId = window.setTimeout(fetchSystemStatus, CHECK_PERIOD);
        }
        if (state.recievedUnauthorizedApiResponse) {
            handleStatusChange();
        }
        return () => {
            isMounted = false;
            window.clearTimeout(timeoutId);
            document.removeEventListener('visibilitychange', handleStatusChange);
        };
    }, [user.id, user.isHR, user.isAdmin, user.isAssistant, state.recievedUnauthorizedApiResponse]);
    return state;
};
const useShouldShowOutdatedVersionBanner = (serverRev) => {
    const [showBanner, setShowBanner] = useState(false);
    const timeoutRef = useRef();
    useEffect(() => {
        if (serverRev === __currentRev__) {
            window.clearTimeout(timeoutRef.current);
            timeoutRef.current = undefined;
        }
        else if (!timeoutRef.current) {
            timeoutRef.current = window.setTimeout(() => setShowBanner(true), OUTDATED_VERSION_WARNING_DELAY);
        }
    }, [serverRev]);
    useEffect(() => () => window.clearTimeout(timeoutRef.current), []);
    return showBanner;
};
const useModalState = (baseCondition, onCloseTrigger, gaLabel) => {
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        if (baseCondition) {
            setShowModal(true);
            trackEvent({
                category: 'SYSTEM',
                label: gaLabel,
                nonInteractive: true,
            });
        }
    }, [baseCondition, gaLabel]);
    const onClose = useCallback(() => {
        setShowModal(false);
        onCloseTrigger();
    }, [onCloseTrigger]);
    return [baseCondition && showModal, onClose];
};
const useSchedulePageReloadOnVersionChange = (serverRev) => {
    useEffect(() => {
        const isVersionUpToDate = serverRev === __currentRev__;
        if (isVersionUpToDate) {
            return;
        }
        schedulePageReload();
        trackEvent({
            category: 'SYSTEM',
            label: `Version has changed: ${__currentRev__} <> ${serverRev}`,
            action: 'Scheduled page reload',
            nonInteractive: true,
        });
    }, [serverRev]);
};
const UserSessionWarnings = () => {
    const { isLoggedIn, permissionsChanged, serverRev } = useSystemStatus();
    const showOutdatedVersionBanner = useShouldShowOutdatedVersionBanner(serverRev);
    const [showLoggedOutModal, closeLoggedOutModal] = useModalState(!isLoggedIn, schedulePageReload, `Show 'logged out' modal`);
    useSchedulePageReloadOnVersionChange(serverRev);
    return (_jsxs(_Fragment, { children: [_jsx(LoggedOutBanner, { show: !isLoggedIn }), _jsx(OutdatedVersionBanner, { show: showOutdatedVersionBanner }), _jsx(PermissionsChangedBanner, { show: permissionsChanged }), _jsx(UserLoggedOutModal, { isOpen: showLoggedOutModal, onClose: closeLoggedOutModal })] }));
};
export default UserSessionWarnings;
