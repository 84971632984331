import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { compose } from 'lodash/fp';
import api from '../../../../api';
import PageHeader from '../../../components/PageHeader';
import PageStatic from '../../../components/PageStatic';
import PageTitle from '../../../components/PageTitle';
import { withAppContext } from '../../../hoc/withAppContext';
import { withPageData } from '../../../hoc/withPageData';
import AdminOverview from './presenter';
import { countAdminErrors, groupLinksByCategory } from './service';
function AdminOverviewPage({ adminLinks, appContext }) {
    const title = 'Settings';
    return (_jsxs(PageStatic, { children: [_jsx(PageTitle, { title: title }), _jsx(PageHeader, { title: title }), _jsx(AdminOverview, { categories: groupLinksByCategory(adminLinks), errorCount: countAdminErrors(adminLinks), currentUser: appContext.user })] }));
}
export default compose(withPageData({
    resolve: {
        adminLinks: () => api.admin.getSettingsLinks(),
    },
}), withAppContext)(AdminOverviewPage);
