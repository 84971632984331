import { ANYTIME_FEEDBACK_PROVIDE, ANYTIME_FEEDBACK_REQUEST_EDIT, FEEDBACK_USER_VIEW, FEEDBACK_USER_VIEW_MULTIPLE, FEEDBACK_PROVIDE, } from '../../../../routes';
import { interpolateAppUrl } from '../../../services/Url';
export const getFeedbackViewUrl = (id) => interpolateAppUrl(FEEDBACK_USER_VIEW)({ id: id });
export const getFeedbackYouProvidedViewUrl = (id) => interpolateAppUrl(FEEDBACK_USER_VIEW_MULTIPLE)({ id: id });
export const getFeedbackYouProvidedViewStandaloneUrl = (id) => interpolateAppUrl(FEEDBACK_USER_VIEW)({ id: id });
export const getAnytimeFbEditUrl = (feedback) => {
    return interpolateAppUrl(ANYTIME_FEEDBACK_REQUEST_EDIT)({
        id: feedback.id,
    });
};
export const getFeedbackProvideUrl = (feedback, responseId) => {
    switch (feedback.type) {
        case 'REQUESTED':
            return interpolateAppUrl(ANYTIME_FEEDBACK_PROVIDE)({
                requestId: feedback.id,
                reviewerId: responseId,
            });
        case 'CYCLE':
            return interpolateAppUrl(FEEDBACK_PROVIDE)({
                id: responseId,
            });
    }
};
