import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { isEqual } from 'lodash/fp';
import { divWithClassName } from '../../../services/Component';
import Autocomplete from '../../Autocomplete';
import Checkbox from '../../Checkbox';
import CheckboxContainer from '../../CheckboxContainer';
import DatePicker from '../../DateTime/DatePicker';
import TimePicker from '../../DateTime/TimePicker';
import { GridRadio } from '../../GridRadio';
import Input from '../../Input';
import InputError from '../../Input/InputError';
import { RadioButtonPlain } from '../../RadioButton';
import { RadioButtonContainerPlain } from '../../RadioButtonContainer';
import RichTextArea from '../../RichTextArea';
import { Select } from '../../Select';
import TextArea from '../../TextArea';
import TextareaAutosize from '../../TextareaAutosize';
import Toggle from '../../Toggle';
import styles from './style.m.less';
/** Just a div with some margins/padding */
export const FormInputWrapper = divWithClassName(styles.formInputWrapper);
/** A wrapper around `Input` that prepends a class that adds padding to `input` */
export const FormInput = (_a) => {
    var { className = '' } = _a, props = __rest(_a, ["className"]);
    return (_jsx(Input, Object.assign({ className: classNames(styles.formInput, className) }, props)));
};
export const FormInputWithWrapper = (_a) => {
    var props = __rest(_a, []);
    return (_jsx(FormInputWrapper, { children: _jsx(FormInput, Object.assign({}, props)) }));
};
export const FormCheckboxContainer = props => (_jsx(FormInputWrapper, { children: _jsx(CheckboxContainer, Object.assign({}, props)) }));
export const FormCheckbox = (_a) => {
    var { value } = _a, otherProps = __rest(_a, ["value"]);
    return (_jsx(Checkbox, Object.assign({ checked: value, className: styles.formCheckbox }, otherProps)));
};
export const FormRadioButtonContainer = props => (_jsx(FormInputWrapper, { children: _jsx(RadioButtonContainerPlain, Object.assign({}, props)) }));
export const FormRadioButton = props => _jsx(RadioButtonPlain, Object.assign({}, props));
export const FormRichTextArea = (_a) => {
    var { error } = _a, otherProps = __rest(_a, ["error"]);
    return (_jsxs(FormInputWrapper, { children: [_jsx(RichTextArea, Object.assign({}, otherProps, { className: styles.formRichTextArea, invalid: !!error })), _jsx(InputError, { error: error })] }));
};
export const FormSelect = (_a) => {
    var { error = undefined, value, onChange, wrapperClassName } = _a, otherProps = __rest(_a, ["error", "value", "onChange", "wrapperClassName"]);
    return (_jsxs(FormInputWrapper, { className: wrapperClassName, children: [_jsx(Select, Object.assign({ hasError: !!error }, otherProps, { selected: isEqual(value, '-') ? null : value, onSelect: onChange })), _jsx(InputError, { error: error })] }));
};
export const FormGridRadio = (_a) => {
    var { error = undefined, options, value, wrapperClassName = '', onChange, url } = _a, otherProps = __rest(_a, ["error", "options", "value", "wrapperClassName", "onChange", "url"]);
    return (_jsxs(FormInputWrapper, { className: wrapperClassName, children: [_jsx(GridRadio, Object.assign({ options: options, value: value, onChange: onChange, url: url }, otherProps)), _jsx(InputError, { error: error })] }));
};
export const FormButtonContainer = divWithClassName(classNames('button-container', styles.formButtonContainer));
export const FormDatePicker = (_a) => {
    var { value, error, wrapperClassName = '' } = _a, props = __rest(_a, ["value", "error", "wrapperClassName"]);
    return (_jsx(FormInputWrapper, { className: wrapperClassName, children: _jsx(DatePicker, Object.assign({}, props, { date: value, className: styles.formInput, hasError: !!error })) }));
};
export const FormTextArea = (_a) => {
    var otherProps = __rest(_a, []);
    return (_jsx(FormInputWrapper, { children: _jsx(TextArea, Object.assign({}, otherProps)) }));
};
export const FormTextareaAutosize = props => (_jsx(FormInputWrapper, { children: _jsx(TextareaAutosize, Object.assign({ className: styles.formTextareaAutosize }, props)) }));
export const FormSentence = ({ children }) => _jsx("div", { className: styles.formSentence, children: children });
export const FormSentenceInput = props => _jsx(Input, Object.assign({ className: styles.formSentenceInput }, props));
export const FormSentenceSelect = (_a) => {
    var { value, onChange } = _a, props = __rest(_a, ["value", "onChange"]);
    return (_jsx(Select, Object.assign({ className: styles.formSentenceInput, selected: value, onSelect: onChange }, props)));
};
export const FormToggle = (_a) => {
    var { checked, wrapperClassName = '' } = _a, otherProps = __rest(_a, ["checked", "wrapperClassName"]);
    return (_jsx(FormInputWrapper, { className: wrapperClassName, children: _jsx(Toggle, Object.assign({ checked: checked }, otherProps)) }));
};
export const FormAutocomplete = (_a) => {
    var { value, onChange } = _a, otherProps = __rest(_a, ["value", "onChange"]);
    return (_jsx(Autocomplete, Object.assign({ value: value, onSelect: onChange, className: styles.formInput }, otherProps)));
};
export const FormTimePicker = (_a) => {
    var { wrapperClassName = '' } = _a, props = __rest(_a, ["wrapperClassName"]);
    return (_jsx(FormInputWrapper, { className: wrapperClassName, children: _jsx(TimePicker, Object.assign({}, props)) }));
};
