import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { map, reduce } from 'lodash';
import { linkToMeetingSpacePage } from '../../../../common/services/url-builder';
import replacement from '../../../../react/services/Replacement';
import { getCurrentUser } from '../../../services/CurrentUser';
import { getUrl } from '../../../services/Cycle';
import { modulo } from '../../../services/Math';
import { trackEvent } from '../../../services/Tracking';
import { getUrlToProfile, isManager } from '../../../services/User';
import styles from './style.m.less';
import { preventJavascriptUrls } from '../../../../services/security';
export default function ResultList({ items, selectedItem, totalNumberOfItems, doHideResults, doSelectItem, mode }) {
    const preProcessedItems = preProcess(mode, items);
    const nonSelectable = reduce(preProcessedItems, ({ result, index }, item) => {
        if (item.type === 'HEADER') {
            result.push(index);
        }
        return { result, index: ++index };
    }, { result: [], index: 0 }).result;
    const isSelectedConfigured = isSelected(nonSelectable);
    return (_jsx("ul", { className: classNames('nakedList', styles.list), id: "header_search_bar-result", children: map(preProcessedItems, (item, index) => (_jsx(ResultItem, { item: item, doSelectItem: () => doSelectItem(compensateForNonSelectable(index, nonSelectable), true), itemIsSelected: isSelectedConfigured(index, selectedItem, totalNumberOfItems), doHideResults: () => {
                trackEvent({
                    category: 'navigation header search bar',
                    action: 'click search item',
                    label: item.type.toLowerCase(),
                });
                return doHideResults();
            } }, item.id))) }));
}
function isSelected(nonSelectable) {
    return (index, selectedItem, totalNumberOfItems) => {
        if (selectedItem === null) {
            return false;
        }
        else {
            return compensateForNonSelectable(index, nonSelectable) === modulo(selectedItem, totalNumberOfItems);
        }
    };
}
function compensateForNonSelectable(index, nonSelectable) {
    const nrNonSelectable = reduce(nonSelectable, (memo, indexOfNonSelectable) => {
        return indexOfNonSelectable < index ? memo + 1 : memo;
    }, 0);
    return index - nrNonSelectable;
}
function ResultItem({ item, doHideResults, itemIsSelected, doSelectItem }) {
    return {
        TEAM: (_jsx(Team, { item: item.value, doHideResults: doHideResults, doSelectItem: doSelectItem, itemIsSelected: itemIsSelected })),
        CYCLE_SPECIFIC_REVIEWEES: (_jsx(Team, { item: item.value, doHideResults: doHideResults, doSelectItem: doSelectItem, itemIsSelected: itemIsSelected })),
        CYCLE: (_jsx(Cycle, { item: item.value, doHideResults: doHideResults, doSelectItem: doSelectItem, itemIsSelected: itemIsSelected })),
        SEARCH: (_jsx(Search, { item: item, doHideResults: doHideResults, doSelectItem: doSelectItem, itemIsSelected: itemIsSelected })),
        HEADER: _jsx(Header, { item: item }),
        NOTHING_FOUND: _jsx(NoResults, {}),
    }[item.type];
}
function Header({ item }) {
    return (_jsx("li", { className: styles.segmentTitle, children: _jsx("small", { children: item.value }) }));
}
function NoResults() {
    return (_jsx("li", { className: styles.noResults, children: _jsx("small", { children: "No Results" }) }));
}
function Team({ item, doHideResults, itemIsSelected, doSelectItem }) {
    const props = { item, doHideResults, itemIsSelected, doSelectItem };
    if (item.isSecondaryReviewee) {
        return _jsx(SecondaryReviewee, Object.assign({}, props));
    }
    else if (isManager(getCurrentUser())) {
        return _jsx(DirectReport, Object.assign({}, props));
    }
    else {
        return _jsx(Teammate, Object.assign({}, props));
    }
}
function DirectReport({ item, doHideResults, itemIsSelected, doSelectItem }) {
    const actions = getReportLinks(item, { isDirectReport: true });
    const props = { item, doHideResults, itemIsSelected, doSelectItem, actions };
    return _jsx(UserWithActions, Object.assign({}, props));
}
function SecondaryReviewee({ item, doHideResults, itemIsSelected, doSelectItem }) {
    const actions = getReportLinks(item, { isDirectReport: false });
    const props = { item, doHideResults, itemIsSelected, doSelectItem, actions };
    return _jsx(UserWithActions, Object.assign({}, props));
}
function Teammate({ item, doHideResults, itemIsSelected, doSelectItem }) {
    const itemClasses = classNames(styles.item, {
        [styles.itemSelected]: itemIsSelected,
    });
    return (_jsx("li", { className: styles.listItem, children: _jsxs("a", { tabIndex: "-1", href: getUrlToProfile(item), onClick: doHideResults, className: itemClasses, onMouseOver: doSelectItem, children: [_jsx("div", { className: styles.imageContainer, children: _jsx("img", { src: item.logo, className: styles.image, alt: "" }) }), _jsxs("div", { className: styles.info, children: [_jsx("span", { className: styles.title, children: item.name }), _jsx("span", { className: styles.subTitle, children: item.role })] })] }) }));
}
function UserWithActions({ item, doHideResults, itemIsSelected, doSelectItem, actions }) {
    const itemClasses = classNames(styles.item, {
        [styles.itemSelected]: itemIsSelected,
    });
    const href = getUrlToProfile(item);
    return (_jsxs("li", { className: itemClasses, onMouseOver: doSelectItem, children: [_jsx("a", { tabIndex: "-1", href: preventJavascriptUrls(href), onClick: doHideResults, className: styles.imageContainer, children: _jsx("img", { src: item.logo, className: styles.image, alt: "" }) }), _jsxs("div", { className: styles.info, children: [_jsx("a", { tabIndex: "-1", className: styles.titleTeam, href: preventJavascriptUrls(href), onClick: doHideResults, children: item.name }), _jsx("div", { className: styles.actions, children: map(actions, action => (_jsx("a", { tabIndex: "-1", className: styles.action, href: preventJavascriptUrls(action.url), onClick: doHideResults, children: action.name }, action.url))) })] })] }));
}
function getReportLinks(teamMember, { isDirectReport }) {
    const enabledFeatures = getCurrentUser().company.enabledFeatures;
    const links = [];
    if (enabledFeatures.objective) {
        links.push({
            name: replacement('objective.plural.big'),
            url: '/app/user/' + teamMember.id + '/?tab=objectives',
        });
    }
    if (enabledFeatures.feedback) {
        links.push({
            name: replacement('fr.short.big'),
            url: '/app/user/' + teamMember.id + '/?tab=360s',
        });
    }
    if (enabledFeatures.review) {
        links.push({
            name: replacement('pr.plural.short'),
            url: '/app/user/' + teamMember.id + '/?tab=reviews',
        });
    }
    if (enabledFeatures.message) {
        links.push({
            name: 'Messages',
            url: '/app/user/' + teamMember.id + '/?tab=messages',
        });
    }
    if (enabledFeatures.meetings && isDirectReport) {
        links.push({
            name: '1:1s',
            url: linkToMeetingSpacePage(teamMember),
        });
    }
    return links;
}
function Cycle({ item, doHideResults, itemIsSelected, doSelectItem }) {
    const itemClasses = classNames(styles.item, styles.itemSmall, {
        [styles.itemSelected]: itemIsSelected,
    });
    return (_jsx("li", { className: itemClasses, onMouseOver: doSelectItem, children: _jsx("a", { tabIndex: "-1", href: preventJavascriptUrls(getUrl(getCurrentUser(), item)), className: styles.linkSmall, onClick: doHideResults, children: _jsx("div", { className: styles.mainContent, children: item.name }) }) }));
}
function Search({ item, doHideResults, itemIsSelected, doSelectItem }) {
    const itemClasses = classNames(styles.item, {
        [styles.itemInactive]: !item.value.data.active,
        [styles.itemSelected]: itemIsSelected,
    });
    /* eslint-disable react/no-danger */
    return (_jsx("li", { className: styles.listItem, children: _jsxs("a", { tabIndex: "-1", href: preventJavascriptUrls(item.value.url), className: itemClasses, onMouseOver: doSelectItem, onClick: doHideResults, id: itemIsSelected ? 'header_search_bar-result--selected-item' : '', "aria-label": `${item.value.plainValue}, ${item.value.plainRole}`, children: [_jsx("div", { className: styles.imageContainer, children: _jsx("img", { src: item.value.data.logoUrl, className: styles.image, alt: "" }) }), _jsxs("div", { className: styles.info, children: [_jsx("span", { className: styles.title, dangerouslySetInnerHTML: { __html: item.value.formattedValue } }), _jsx("span", { className: styles.subTitle, dangerouslySetInnerHTML: { __html: item.value.formattedRole } })] }), _jsx("strong", { className: styles.label, children: item.value.typeFormatted })] }) }));
    /* eslint-enable react/no-danger */
}
function preProcess(mode, items) {
    if (items.length === 0) {
        return [
            {
                type: 'NOTHING_FOUND',
                id: 'NOTHING_FOUND',
            },
        ];
    }
    else {
        return ({
            CYCLE: intersperseHeadingsToCycles(items),
            TEAM: intersperseHeadingsToTeam(items),
        }[mode] || items);
    }
}
function intersperseHeadingsToCycles(items) {
    const getHeading = item => ({
        type: 'HEADER',
        id: getHeader(item.value.type),
        value: getHeader(item.value.type),
    });
    return intersperseHeadings(item => item.value.type, getHeading, items);
}
function intersperseHeadings(getCategory, getHeading, items) {
    const withHeadings = reduce(items, (memo, item) => {
        if (getCategory(item) && !memo.categoriesDiscovered[getCategory(item)]) {
            memo.result.push(getHeading(item));
            memo.categoriesDiscovered[getCategory(item)] = true;
        }
        memo.result.push(item);
        return memo;
    }, { categoriesDiscovered: {}, result: [] });
    return withHeadings.result;
}
function intersperseHeadingsToTeam(items) {
    const getType = item => {
        if (item.value.isSecondaryReviewee) {
            return 'SECONDARY';
        }
        else if (item.type === 'CYCLE_SPECIFIC_REVIEWEES') {
            return item.type;
        }
        else {
            return null;
        }
    };
    const getHeading = item => ({
        type: 'HEADER',
        id: getHeader(getType(item)),
        value: getHeader(getType(item)),
    });
    return intersperseHeadings(getType, getHeading, items);
}
function getHeader(type) {
    switch (type) {
        case 'REVIEW':
            return replacement('pr.singular.short') + ' Cycles';
        case 'OBJECTIVE':
            return replacement('objective.singular.big') + ' Cycles';
        case 'FEEDBACK':
            return replacement('fr.short.big') + ' Cycles';
        case 'SECONDARY':
            return 'Secondary Reviewees';
        case 'CYCLE_SPECIFIC_REVIEWEES':
            return 'Cycle Specific Reviewees';
    }
}
