import { filter, forEach, uniq, map, keyBy, some, includes } from 'lodash';
const subscribers = [];
setupClickListener();
setupKeyListener();
export const onClickOrEsc = fn => {
    const fnWrapped = ev => {
        if (!(ev instanceof window.KeyboardEvent) || ev.keyCode === 27) {
            ev.stopPropagation();
            ev.preventDefault();
            fn(ev);
        }
    };
    document.addEventListener('click', fnWrapped);
    document.addEventListener('keydown', fnWrapped, true);
    return fnWrapped;
};
export const removeOnClickOrEsc = fn => {
    document.removeEventListener('keydown', fn, true);
    document.removeEventListener('click', fn, false);
};
export function subscribeOutsideClick(elementId, handler) {
    subscribers.push({
        elementId,
        handler,
        type: 'outside',
    });
}
export function subscribeKeyPress(handler) {
    subscribers.push({
        handler,
        type: 'keypress',
    });
}
function notifyOutsideClick(elementId, ev) {
    const isRelevant = x => x.elementId === elementId && x.type === 'outside';
    notify(isRelevant, ev);
}
function notifyKeyPress(ev) {
    const isRelevant = x => x.type === 'keypress';
    notify(isRelevant, ev);
}
function notify(isRelevant, ev) {
    const toNotify = filter(subscribers, isRelevant);
    forEach(toNotify, x => x.handler(ev));
}
function setupClickListener() {
    document.addEventListener('click', ev => {
        const elementIds = uniq(map(subscribers, 'elementId'));
        forEach(elementIds, elementId => {
            for (var element = ev.target; element; element = element.parentNode) {
                if (element.id === elementId) {
                    return;
                }
            }
            notifyOutsideClick(elementId, ev);
        });
    });
}
function setupKeyListener() {
    const blackList = getBlackList();
    const elementBlackList = keyBy(map(filter(blackList, x => x.element), 'element'));
    const attributeBlackList = keyBy(map(filter(blackList, x => x.attribute), 'attribute'));
    const classNameBlackList = keyBy(map(filter(blackList, x => x.className), 'className'));
    document.addEventListener('keypress', ev => {
        const activeElement = document.activeElement;
        const shouldBlockEvent = some([
            elementIsDisallowedTag(activeElement, elementBlackList),
            elementHasDisallowedClass(activeElement, classNameBlackList),
            elementHasDisallowedAttribute(activeElement, attributeBlackList),
        ]);
        if (!isOverlayActive() && (!shouldBlockEvent || ifInputAllowCheckbox(activeElement))) {
            notifyKeyPress(ev);
        }
    });
}
function elementIsDisallowedTag(element, disallowedTags) {
    const tagName = element.tagName.toLowerCase();
    return disallowedTags[tagName] !== undefined;
}
function elementHasDisallowedClass(element, disallowedClasses) {
    const classes = filter(element.className.split(' '), x => x.length > 0);
    return some(classes, className => disallowedClasses[className] !== undefined);
}
function elementHasDisallowedAttribute(element, disallowedAttributes) {
    const attributes = map(element.attributes, x => x.name);
    return some(attributes, attribute => disallowedAttributes[attribute] !== undefined);
}
function isOverlayActive() {
    const candidates = document.getElementsByClassName('si-ui-lightbox');
    const overlay = filter(candidates, elem => !includes(elem.className.toLowerCase(), 'hide') && !includes(elem.className.toLowerCase(), 'close'));
    return overlay.length > 0;
}
function ifInputAllowCheckbox(element) {
    if (element.tagName.toLowerCase() === 'input') {
        return element.getAttribute('type') && element.getAttribute('type').toLowerCase() === 'checkbox';
    }
}
function getBlackList() {
    return [
        {
            className: 'ql-editor',
        },
        {
            attribute: 'contenteditable',
        },
        {
            element: 'input',
        },
        {
            element: 'textarea',
        },
        {
            element: 'select',
        },
        {
            element: 'option',
        },
        {
            element: 'button',
        },
    ];
}
