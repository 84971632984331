import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import imageAutomate from '../../../../../page/screens/registerPage/onboarding-survey-step-three-automate-highlight.png';
import imageEngagement from '../../../../../page/screens/registerPage/onboarding-survey-step-three-engagement-highlight.png';
import imageFeedback from '../../../../../page/screens/registerPage/onboarding-survey-step-three-feedback-highlight.png';
import imageGoals from '../../../../../page/screens/registerPage/onboarding-survey-step-three-goals-highlight.png';
import imageMeetings from '../../../../../page/screens/registerPage/onboarding-survey-step-three-meetings-highlight.png';
import imagePerformanceReviews from '../../../../../page/screens/registerPage/onboarding-survey-step-three-performance-reviews-highlight.png';
import { updateOnboardingSettings } from '../../../../api/custom/CompanyOnboarding';
import { Form } from '../../../components/Form';
import { FormErrorMessage } from '../../../components/Form/FormErrorMessage';
import RegisterFlowTemplate from '../../../components/RegisterFlowTemplate';
import SaveButton from '../../../components/SaveButton';
import { VerticalRadio } from '../../../components/VerticalRadio';
import { getCurrentUser } from '../../../services/CurrentUser';
import { goToDashboardPage, goToOnboardingSurveyHris } from '../../../services/GoTo';
import { isAdmin } from '../../../services/User';
import styles from '../styles.m.less';
const BUTTON_LABELS = {
    DISABLED: 'Continue',
    ENABLED: 'Continue',
};
const generateInterests = (interests) => {
    return interests.map(interest => ({
        title: interest.title,
        label: interest.title,
        value: interest.title,
        trackingLabel: interest.title,
    }));
};
const INITIAL_INTEREST = { title: '', image: '' };
const INTERESTS = [
    { title: 'Running a performance review', image: imagePerformanceReviews },
    { title: 'Capturing 1:1 meetings', image: imageMeetings },
    { title: 'Automating repetitive tasks', image: imageAutomate },
    { title: 'Tracking goal performance', image: imageGoals },
    { title: 'Facilitating continuous feedback', image: imageFeedback },
    { title: 'Measuring employee engagement', image: imageEngagement },
];
const INTEREST_TITLES = generateInterests(INTERESTS);
export default function OnboardingSurveyInterestStep() {
    const [selectedInterest, setSelectedInterest] = useState(INITIAL_INTEREST);
    const handleInterestChange = (title) => {
        const selectInterest = INTERESTS.find(interest => interest.title === title) || INITIAL_INTEREST;
        setSelectedInterest(selectInterest);
    };
    const redirectAdminUser = () => {
        const currentUser = getCurrentUser();
        if (isAdmin(currentUser)) {
            goToOnboardingSurveyHris();
        }
        else {
            goToDashboardPage();
        }
    };
    const handleSubmit = async () => {
        updateOnboardingSettings({ interest: selectedInterest.title }).then(redirectAdminUser).catch(redirectAdminUser);
    };
    return (_jsx(RegisterFlowTemplate, { step: "3", title: "What is your main interest?", subtitle: "This will help us tailor small improvements to you", imageURL: selectedInterest.image !== '' ? selectedInterest.image : imagePerformanceReviews, children: _jsx(Form, { onSubmit: handleSubmit, formClassName: styles.registerFormClass, children: ({ submitError, invalid }) => (_jsxs("div", { className: styles.signUpIn, children: [_jsx(VerticalRadio, { value: selectedInterest.title, options: INTEREST_TITLES, onChange: e => handleInterestChange(e) }), _jsxs("div", { className: styles.formButtonContainer, children: [_jsx(SaveButton, { state: 'ENABLED', labels: BUTTON_LABELS, disabled: invalid, className: styles.register_form_button }), submitError ? _jsx(FormErrorMessage, { errorMessage: submitError }) : null] })] })) }) }));
}
