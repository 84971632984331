import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { compose, setPropTypes } from 'recompose';
import { withData } from '../../../../hoc/withData';
import api from '../../../../../api';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getAuthorId } from '../../services/review';
import NoAssessment from '../EditPage/NoAssessment';
import RouteError from '../../../../../routing/RouteError';
import { getNoReviewerOnPreviewQuestionnaireError } from '../../services/wording';
import { getPreviewQuestionnaireActionUrls } from '../../services/url';
import { getCurrentUser } from '../../../../services/CurrentUser';
import { useAppContext } from '../../../../hooks/useAppContext';
import { mapProps } from '../../../../hoc/recompose';
const errorComponent = ({ error }) => {
    if (!error) {
        return null;
    }
    switch (get('response.data.message', error)) {
        case 'REVIEWER_NOT_FOUND':
            return _jsx(NoAssessment, { message: getNoReviewerOnPreviewQuestionnaireError() });
        default:
            return _jsx(RouteError, { error: error });
    }
};
export default compose(setPropTypes({
    cycleId: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
}), withData({
    pendingComponent: LoadingIndicator,
    errorComponent: errorComponent,
    resolve: {
        component: () => import(/* webpackChunkName: 'ReviewsEditPage' */ '../EditPage/presenter'),
    },
    observe: {
        review: ({ cycleId }) => api.reviews.getReviewPreviewQuestionnaire.createObservable(cycleId),
        assessments: ({ cycleId }) => api.reviewAssessments.getAssessmentsForPreviewQuestionnaire.createObservable(cycleId),
    },
}), mapProps(props => (Object.assign(Object.assign({}, props), { authorId: getAuthorId(props.location, props.review), previewQuestionnaireActions: getPreviewQuestionnaireActionUrls(props.cycleId), isQuestionnairePreview: true, currentUser: getCurrentUser() }))))((_a) => {
    var { component: { default: Component } } = _a, props = __rest(_a, ["component"]);
    return _jsx(Component, Object.assign({}, props));
});
export const PreviewEditForCycleModel = compose(setPropTypes({
    cycleModel: PropTypes.object.isRequired,
}), withData({
    pendingComponent: LoadingIndicator,
    errorComponent: errorComponent,
    resolve: {
        component: () => import(/* webpackChunkName: 'ReviewsEditPage' */ '../EditPage/presenter'),
        review: ({ cycleModel }) => api.reviews.getPreviewReviewForCycleModel(cycleModel),
        assessments: ({ cycleModel }) => api.reviewAssessments.getPreviewAssessmentsForCycleModel(cycleModel),
    },
}))((_a) => {
    var { component: { default: Component } } = _a, props = __rest(_a, ["component"]);
    const { user } = useAppContext();
    return _jsx(Component, Object.assign({ isQuestionnairePreview: true, reveal: true, currentUser: user }, props, { authorId: user.id }));
});
