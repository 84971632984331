import moment from 'moment-timezone';
import { formatNumber } from '../../../common/services/number-formatter';
import { curry } from 'lodash/fp';
const WITHOUT_TIMEZONE_LONG = 'YYYY-MM-DDTHH:mm:ss';
const WITHOUT_TIMEZONE_SHORT = 'YYYY-MM-DD';
const DESCRIPTIVE_DATE = 'dddd, MMMM Do YYYY [at] H:mm';
export function isOlderThan(date, difference) {
    // in case difference is a number wrap in an object so it defaults to minutes
    difference = !isNaN(parseFloat(difference)) && isFinite(difference) ? { minutes: difference } : difference;
    var diffFromNow = moment(moment()).diff(date), differenceSeconds = moment.duration(difference);
    return diffFromNow < differenceSeconds;
}
const MIDNIGHT = { hour: 0, minute: 0, second: 0, millisecond: 0 };
export const isOnSameDay = curry((date1, date2) => moment(date1).set(MIDNIGHT).diff(moment(date2).set(MIDNIGHT), 'days') === 0);
export function isInPast(date, now) {
    return moment(now).isAfter(date);
}
export function isInFuture(date, referenceDate) {
    return moment(referenceDate).isBefore(date);
}
export function isMoreThanXDaysInTheFuture(days, date, referenceDate) {
    const reference = moment(referenceDate).add(days, 'days');
    return reference.isBefore(date);
}
export function getDaysLeft(referenceDate, now) {
    const daysLeft = -moment.duration(moment(now).diff(referenceDate)).asDays();
    return formatNumber(daysLeft);
}
export function getTimeUnitInMilliseconds(timeUnit, value) {
    return moment.duration(value, timeUnit).asMilliseconds();
}
export function getDateInUnixMillisecondsInt(inputDate, optionalInputFormat) {
    return parseInt(moment(inputDate, optionalInputFormat).format('x'));
}
export function convertMomentDayInTimeZone(timestamp, timeZone) {
    return moment.tz(timestamp.format(WITHOUT_TIMEZONE_SHORT), timeZone);
}
export function convertTimestampInTimeZone(timestamp, timeZone) {
    return moment.tz(moment(timestamp).format(WITHOUT_TIMEZONE_LONG), timeZone);
}
export function withBusinessHourInCompanyTimeZone(dateOrMoment, currentUser) {
    const { companySettings } = currentUser.company;
    const { endOfBusiness, timeZone } = companySettings;
    const time = moment(dateOrMoment).startOf('day').add(endOfBusiness, 'hours');
    return convertTimestampInTimeZone(time, timeZone);
}
export const LOCAL_TIMEZONE = {};
export function formatDate(input, dateFormat, timeZone, displayTimeZone, companySettings) {
    if (!input) {
        return '';
    }
    if (!isNaN(input) && !moment.isMoment(input) && isIntParsable(input)) {
        input = parseInt(input);
    }
    const dateFormats = Object.assign({ shortDate: companySettings.dateFormatCoarse || 'L', fullDate: companySettings.dateFormat || 'LLL' }, companySettings.dateFormats);
    if (typeof dateFormat === 'string' && dateFormats[dateFormat]) {
        dateFormat = dateFormats[dateFormat];
    }
    var cFormat = dateFormat || dateFormats.fullDate;
    var cTimezone = timeZone === LOCAL_TIMEZONE ? moment.tz.guess() : timeZone || companySettings.timeZone;
    var cMoment = moment.tz(input, cTimezone);
    var formattedDate = cMoment.format(cFormat);
    if (displayTimeZone) {
        formattedDate = formattedDate + ' ' + cMoment.zoneName();
    }
    return formattedDate;
}
export function toRelativeDate(input) {
    // Default behavior of moment.js is not what we want:
    // http://momentjs.com/docs/#/displaying/fromnow/
    // Dates up to 48 hours are not supposed to be rounded to days but to be displayed as hours.
    input = toDate(input);
    var diffHours = moment().diff(input, 'hours');
    var diffHoursPositive = -diffHours > 0 ? -diffHours : diffHours;
    var relativeDate = moment(input).fromNow();
    if (diffHoursPositive < 48 && diffHoursPositive > 2) {
        relativeDate = diffHoursPositive + ' hours';
        if (diffHours > 0) {
            relativeDate += ' ago';
        }
        else {
            relativeDate = 'in ' + relativeDate;
        }
    }
    return relativeDate;
}
export function toMinimalDate(input, companySettings) {
    moment.updateLocale('en', {
        relativeTime: {
            s: 'a moment',
            m: '1 minute',
            mm: '%d mins',
            h: '1 hr',
            hh: '%d hrs',
            d: '1 day',
        },
        calendar: {
            lastDay: companySettings.dateFormats.yesterdayApprox,
            lastWeek: companySettings.dateFormats.thisYearApprox,
            sameElse: isThisYear(input)
                ? companySettings.dateFormats.thisYearApprox
                : companySettings.dateFormats.lastYearApprox,
        },
    });
    input = toDate(input);
    const diffHours = moment().diff(input, 'hours');
    // If activity happened less than 24 hours since now,
    // show values like 'a moment ago', '34 min ago', '4 hrs ago'
    if (diffHours < 24) {
        return moment(input).fromNow();
    }
    // Else, show values using the 'calendar' function
    return moment(input).calendar();
}
function isThisYear(input) {
    return moment(input).year() === moment().year();
}
export function descriptiveDate(input, settings) {
    return formatDate(input, DESCRIPTIVE_DATE, undefined, undefined, settings);
}
export function toRelativeCalendarDate(input) {
    input = toDate(input);
    const now = moment();
    const relative = moment(input).hour(now.hour()).minute(now.minute());
    const relativeDate = `[${relative.fromNow()}]`;
    return moment(input).calendar(null, {
        sameDay: '[today]',
        nextDay: '[tomorrow]',
        lastDay: '[yesterday]',
        nextWeek: relativeDate,
        lastWeek: relativeDate,
        sameElse: relativeDate,
    });
}
export function isCloseToHoursFromNow(date, range) {
    const border1 = moment().subtract(range, 'hours');
    const border2 = moment().add(range, 'hours');
    return moment(date).isBetween(border1, border2);
}
export function toDate(input) {
    if (!isNaN(input) && !moment.isMoment(input)) {
        input = parseInt(input);
    }
    return input;
}
export function timestampToIsoDateInCompanyTimezone(timestamp, currentUser) {
    const timezone = currentUser.company.companySettings.timeZone;
    return moment.tz(timestamp, timezone).format(moment.HTML5_FMT.DATE);
}
function isIntParsable(date) {
    return !!parseInt(date);
}
export function wants12Hr(companySettings) {
    return companySettings.dateFormats.minimalTime === 'hA';
}
