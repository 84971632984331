import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import cN from 'classnames';
import styles from './style.m.less';
import * as React from 'react';
export function Card(_a) {
    var { className, isCompact = false } = _a, props = __rest(_a, ["className", "isCompact"]);
    return _jsx("article", Object.assign({ className: cN('card', className, { [styles.compact]: isCompact }) }, props));
}
/**
 *  The Card Header inside a card
 *  A CardHeader has CardHeaderHeading and CardHeaderButtons inside it
 */
export function CardHeader(_a) {
    var { className } = _a, props = __rest(_a, ["className"]);
    return _jsx("header", Object.assign({ className: cN('card-header', className) }, props));
}
export function CardHeaderHeading(_a) {
    var { className } = _a, props = __rest(_a, ["className"]);
    return _jsx("h1", Object.assign({ className: cN('card-header-heading', className) }, props));
}
export function CardHeaderButtons(_a) {
    var { className } = _a, props = __rest(_a, ["className"]);
    return _jsx("div", Object.assign({ className: cN('card-header-buttons', className) }, props));
}
/**
 *  A Section inside a card
 *  A CardSection has CardSectionHeading and CardBody inside it
 */
export function CardSection(_a) {
    var { className } = _a, props = __rest(_a, ["className"]);
    return _jsx("section", Object.assign({ className: cN('card-section', className) }, props));
}
export function CardSectionHeading(_a) {
    var { className } = _a, props = __rest(_a, ["className"]);
    return _jsx("h1", Object.assign({ className: cN('card-section-heading', className) }, props));
}
export function CardSectionBody(_a) {
    var props = __rest(_a, []);
    return _jsx("div", Object.assign({}, props));
}
export function ThumbCard(_a) {
    var { className, isEnabled = true, isCompact = false } = _a, props = __rest(_a, ["className", "isEnabled", "isCompact"]);
    return (_jsx("article", Object.assign({ className: cN('card', styles.interactive, isEnabled ? styles.interactiveEnabled : styles.interactiveDisabled, { [styles.compact]: isCompact }, className) }, props)));
}
