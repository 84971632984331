import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { IconSearch, IconX } from 'featherico';
import { get } from 'lodash/fp';
import { AriaAnnouncer, AriaLabel } from '../../../components/AriaHelper';
import Button from '../../../components/Button';
import Spinner from '../../../components/Spinner';
import { getCurrentUser } from '../../../services/CurrentUser';
import { createQuery, CYCLE, getPages, SEARCH, TEAM } from '../../../services/HeaderSearchBar';
import { modulo } from '../../../services/Math';
import { trackEvent } from '../../../services/Tracking';
import { hasBasicHr, isManager } from '../../../services/User';
import styles from './style.m.less';
export default function SearchInput({ query, showResults, inputVisible, status, mode, selectedItem, items, actions }) {
    const wrapperClasses = classNames('header_search_bar-search_input', {
        'header_search_bar-search_input--closed': !showResults && !inputVisible,
        'header_search_bar-search_input--no_results_showing': !showResults && inputVisible,
        'header_search_bar-search_input--open': showResults,
    });
    return (_jsxs("div", { className: wrapperClasses, children: [mode === 'SEARCH' && showResults && (_jsxs(AriaAnnouncer, { id: "header_search_bar-description", children: [items.length === 1 ? 'There is 1 result.' : `There are ${items.length} results.`, " Use arrow keys to navigate results."] })), _jsx(AriaLabel, { id: "header_search_bar-label", children: "Type to quickly jump to users or cycles" }), _jsx("input", { "aria-label": get('value.plainValue', items[selectedItem]), "aria-autocomplete": "none", "aria-expanded": showResults, "aria-owns": "header_search_bar-result", "aria-activedescendant": "header_search_bar-result--selected-item", "aria-labelledby": "header_search_bar-label", "aria-describedby": "header_search_bar-description", type: "text", value: query, ref: setFocus, onChange: onQueryChange, onClick: e => {
                    trackEvent({ category: 'navigation header search bar', action: 'click jump to' });
                    return onInputClick(e);
                }, onKeyDown: onKeyPress, id: "header_search_bar-search_input-input", className: "header_search_bar-search_input-input", autoComplete: "off" }), _jsxs("label", { htmlFor: "header_search_bar-search_input-input", className: "header_search_bar-search_input-label", "aria-hidden": "true", children: [getIcon(status, showResults, inputVisible, onHideClick), _jsxs("div", { className: "header_search_bar-search_input-label-text", title: 'Press "j" to open', children: [_jsx(IconSearch, { className: "header_search_bar-search_input-label-text-icon" }), ' ', _jsx("span", { className: "app-header-item-label", children: "Jump to..." })] })] })] }));
    function setFocus(element) {
        if (!element) {
            return;
        }
        if (!inputVisible) {
            element.blur();
        }
        else if (showResults) {
            element.focus();
        }
    }
    function onQueryChange(ev) {
        const newQuery = ev.target.value;
        if (query === newQuery) {
            return false;
        }
        actions.doUpdateQuery(newQuery);
        if (newQuery !== '') {
            actions.debouncedSearch(newQuery);
        }
        else {
            actions.debouncedSearch.cancel();
            actions.search(createQuery(TEAM, getCurrentUser()));
        }
    }
    function onHideClick(ev) {
        if (showResults || inputVisible) {
            ev.preventDefault();
            actions.doUpdateQuery('');
            actions.doClearResult();
            actions.doHideResults();
        }
    }
    function onKeyPress(ev) {
        const LEFT = 37;
        const RIGHT = 39;
        const UP = 38;
        const DOWN = 40;
        const ENTER = 13;
        const ESC = 27;
        const handler = {
            [LEFT]: onLeftPress,
            [RIGHT]: onRightPress,
            [UP]: onUpPress,
            [DOWN]: onDownPress,
            [ENTER]: onEnterPress,
            [ESC]: onEscPress,
        }[ev.keyCode] || (x => x);
        handler(ev);
    }
    function onLeftPress() {
        if (mode === 'SEARCH' || !showResults || !isAllowedToSeeCycles(getCurrentUser())) {
            return;
        }
        getOtherMode(mode);
        if (selectedItem !== null) {
            actions.doSelectItem(0);
        }
    }
    function onRightPress() {
        if (mode === 'SEARCH' || !showResults || !isAllowedToSeeCycles(getCurrentUser())) {
            return;
        }
        getOtherMode(mode);
        if (selectedItem !== null) {
            actions.doSelectItem(0);
        }
    }
    function isAllowedToSeeCycles(user) {
        return hasBasicHr(user) || isManager(user);
    }
    function onUpPress(ev) {
        ev.preventDefault();
        if (inputVisible && !showResults) {
            actions.doShowResults();
        }
        else {
            actions.doSelectPrevItem();
        }
    }
    function onDownPress(ev) {
        ev.preventDefault();
        if (inputVisible && !showResults) {
            actions.doShowResults();
        }
        else {
            actions.doSelectNextItem();
        }
    }
    function onEnterPress() {
        if (selectedItem === null || !showResults) {
            return;
        }
        const pages = getPages(mode, items);
        const totalNumberOfItems = pages.length + items.length;
        const allItems = items.concat(pages);
        const index = modulo(selectedItem, totalNumberOfItems);
        actions.doHideResults();
        actions.doRedirect(allItems[index].value.url);
    }
    function onEscPress() {
        if (!inputVisible) {
            return;
        }
        actions.doUpdateQuery('');
        actions.doSelectItem(null);
        actions.doHideResults();
    }
    function getOtherMode(currentMode) {
        if (currentMode !== 'TEAM') {
            actions.search(createQuery(TEAM, getCurrentUser()));
        }
        else {
            actions.search(createQuery(CYCLE));
        }
    }
    function onInputClick() {
        if (!showResults) {
            actions.doShowResults();
            if (query && query !== '') {
                actions.search(createQuery(SEARCH, query));
            }
            else {
                actions.search(createQuery(TEAM, getCurrentUser()));
            }
        }
    }
}
function getIcon(status, showResults, inputVisible, onHideClick) {
    if ((inputVisible === true || showResults === true) && status === 'LOADED') {
        return (_jsx(Button, { kind: "PLAIN", tabIndex: "-1", onClick: onHideClick, className: styles.clearButton, "aria-label": "Cancel search", eventTracking: { category: 'navigation header search bar', action: 'click close' }, children: _jsx(IconX, {}) }));
    }
    else if ((inputVisible === true || showResults === true) && status === 'LOADING') {
        return _jsx(Spinner, { isSpinning: true, className: "header_search_bar-search_input-spinner" });
    }
}
