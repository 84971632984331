import { jsx as _jsx } from "react/jsx-runtime";
import * as icons from 'featherico';
import { camelCase, compose, map, upperFirst } from 'lodash/fp';
import { memoize } from 'lodash';
import { render, unmountComponentAtNode } from 'react-dom';
const pascalCase = compose(upperFirst, camelCase);
const replacePromises = new WeakMap();
export function replaceIcons(node = document.body) {
    const toReplace = node.querySelectorAll('[data-icon]');
    return Promise.all(map(replaceIcon, toReplace));
}
export function replaceIconsInHtml(html) {
    const fragment = document.createRange().createContextualFragment(html);
    const container = document.createElement('div');
    container.appendChild(fragment);
    return replaceIcons(container).then(() => container.innerHTML);
}
function getIconByNameNotMemoized(iconName, props = {}) {
    const Icon = icons[pascalCase('icon-' + iconName)];
    if (!Icon) {
        return Promise.reject(`Icon ${iconName} not found.`);
    }
    return getHtmlOfIcon(Icon, props);
}
export const getIconByName = memoize(getIconByNameNotMemoized, (name, props) => `${name}-${!!(props === null || props === void 0 ? void 0 : props.small)}-${props === null || props === void 0 ? void 0 : props.className}`);
function replaceIcon(node) {
    if (replacePromises.has(node)) {
        return replacePromises.get(node);
    }
    const iconName = node.dataset.icon;
    const promise = getIconByName(iconName, {
        className: node.getAttribute('class'),
        small: node.dataset.small,
    }).then(html => {
        const newNode = document.createRange().createContextualFragment(html).firstChild;
        node.parentNode.replaceChild(newNode, node);
    });
    replacePromises.set(node, promise);
    return promise;
}
function getHtmlOfIcon(Icon, props) {
    return new Promise(resolve => {
        const container = document.createElement('div');
        render(_jsx(Icon, Object.assign({}, props)), container, () => {
            const { innerHTML } = container;
            unmountComponentAtNode(container);
            resolve(innerHTML);
        });
    });
}
