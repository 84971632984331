import { trim, upperFirst } from 'lodash/fp';
import { sanitize } from '../../../common/services/sanitizer/service';
import PERSONALIZATION_REPLACEMENTS from '../../../react/constants/personalization-replacements';
import { concatAnd } from '../../../react/services/Enumeration';
import * as genderService from '../../../react/services/Gender';
export { personalize, personalizeWithHint, personalizeMulti, personalizeWithHintMulti, isBehindSentenceEnd };
const REPLACEMENT_RE = /{{\s?(.+?)\s?}}/g;
function personalize(tpl, user, generateReplacement = defaultGenerator) {
    tpl = tpl || ''; // can't use default param because it might be null
    const replacements = generateReplacements(user);
    return replaceInTemplate(tpl, replacements, generateReplacement);
}
function personalizeMulti(tpl, users, collectionName, generateReplacement = defaultGenerator) {
    if (users.length === 1) {
        return personalize(tpl, users[0], generateReplacement);
    }
    tpl = tpl || ''; // can't use default param because it might be null
    const replacements = generateReplacementsMulti(users, collectionName);
    return replaceInTemplate(tpl, replacements, generateReplacement);
}
function replaceInTemplate(tpl, replacements, fallbackGenerator) {
    return tpl.replace(REPLACEMENT_RE, function (m, g1, i) {
        const key = trim(g1);
        if (!key) {
            return m;
        }
        let replacement = replacements[key];
        if (!replacement && fallbackGenerator === hintGenerator) {
            replacement = key;
        }
        const val = tryToCapitalize(replacement, tpl, i);
        return fallbackGenerator(val);
    });
}
function personalizeWithHint(tpl, user) {
    return personalize(tpl, user, hintGenerator);
}
function personalizeWithHintMulti(tpl, users, collectionName) {
    return personalizeMulti(tpl, users, collectionName, hintGenerator);
}
function tryToCapitalize(str, context, i) {
    return i === 0 || isBehindSentenceEnd(context, i) ? upperFirst(str) : str;
}
function isBehindSentenceEnd(context, i) {
    if (i < 2) {
        return false;
    }
    const before = context[i - 2];
    return /[.?!]/.test(before);
}
function generateReplacements(user) {
    const { gender, firstName, lastName, name } = user;
    return Object.assign(Object.assign({}, genderService.getPronouns(gender)), { [PERSONALIZATION_REPLACEMENTS.FIRST_NAME]: sanitize(firstName), [PERSONALIZATION_REPLACEMENTS.LAST_NAME]: sanitize(lastName), [PERSONALIZATION_REPLACEMENTS.FULL_NAME]: sanitize(name) });
}
function generateReplacementsMulti(users, collectionName) {
    return Object.assign(Object.assign({}, genderService.getPluralPronouns()), { [PERSONALIZATION_REPLACEMENTS.FIRST_NAME]: enumerateUsers(users, u => u.firstName, 3, collectionName), [PERSONALIZATION_REPLACEMENTS.LAST_NAME]: enumerateUsers(users, u => u.lastName || '', 3, collectionName), [PERSONALIZATION_REPLACEMENTS.FULL_NAME]: enumerateUsers(users, u => u.name, 3, collectionName) });
}
function enumerateUsers(users, mapFn, maxItems, fallback) {
    if (users.length > maxItems) {
        return fallback;
    }
    return concatAnd(users.map(mapFn));
}
function defaultGenerator(str) {
    return str;
}
function hintGenerator(str) {
    return `<autocomplete-blot>${sanitize(str)}</autocomplete-blot>`;
}
