// Register with an object of the following shape:
//
// {
//     onKey: function(event) {
//         // If you want to stop propagation to other listeners on the stack
//         // return false explicitly.
//     }
// }
//
// If an angular digest is required, it is the clients responsibility to trigger it.
const stack = [];
export const KEYCODES = {
    enter: 13,
    esc: 27,
    arrowUp: 38,
    arrowDown: 40,
};
export { register, deregister, emitKeyEvent, isEsc, handleActions, stopEvent, getStack };
function register(listener, scope) {
    stack.unshift(listener);
    if (scope && scope.$on) {
        scope.$on('$destroy', () => deregister(listener));
    }
}
function deregister(listener) {
    const i = stack.indexOf(listener);
    if (i !== -1) {
        stack.splice(i, 1);
    }
}
function emitKeyEvent(event) {
    if (!stack.length) {
        return;
    }
    // Copy stack as callbacks might actively start to take items
    // out of the list during the iteration process.
    const secureStack = stack.slice();
    for (var i = 0; i < secureStack.length; i++) {
        const listener = secureStack[i];
        if (listener.onKey) {
            const shouldContinue = listener.onKey(event);
            // Explicit check for false - not a falsy value like undefined!
            if (shouldContinue === false) {
                break;
            }
        }
    }
}
function handleActions(event, actions) {
    if (actions[event.key]) {
        actions[event.key]();
        return stopEvent(event);
    }
}
function stopEvent(event) {
    event.stopPropagation();
    event.preventDefault();
    return false;
}
function isEsc(event) {
    return event.keyCode === KEYCODES.esc;
}
function getStack() {
    return stack;
}
