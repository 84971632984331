import * as R from '../../../routes';
import { getAdminRouteForNavigation } from '../../../routes-pairs';
import { getAngularServiceWeak } from '../AngularService';
import { getCurrentUser } from '../CurrentUser';
import { redirect } from '../Router';
function redirectToApp(url, params, forceReload) {
    redirect(`/app${url}`, params, forceReload);
}
export function goToReviewCycleOverview(cycleId) {
    var _a;
    (_a = getAngularServiceWeak('ReviewCycleLookupService')) === null || _a === void 0 ? void 0 : _a.reset();
    redirectToApp(getAdminRouteForNavigation(R.YOUR_COMPANY_REVIEWS_WITH_CYCLE), { cycleId });
}
export function goToReviewCycleManagement() {
    redirectToApp(getAdminRouteForNavigation(R.YOUR_COMPANY_REVIEWS_SELECT_CYCLE));
}
export function goToFeedbackCycleOverview(cycleId) {
    var _a;
    (_a = getAngularServiceWeak('FeedbackCycleLookupService')) === null || _a === void 0 ? void 0 : _a.reset();
    redirectToApp(getAdminRouteForNavigation(R.YOUR_COMPANY_FEEDBACK_WITH_CYCLE), { cycleId });
}
export function goToFeedbackCycleManagement() {
    redirectToApp(getAdminRouteForNavigation(R.YOUR_COMPANY_FEEDBACK_SELECT_CYCLE));
}
export function goToObjectiveCycleOverview(cycleId) {
    var _a;
    (_a = getAngularServiceWeak('ObjectiveCycleLookupService')) === null || _a === void 0 ? void 0 : _a.reset();
    redirectToApp(R.YOUR_COMPANY_OBJECTIVES_OVERVIEW, { cycleId });
}
export function goToObjectiveCycleManagement() {
    redirectToApp(getAdminRouteForNavigation(R.YOUR_COMPANY_OBJECTIVES_SELECT_CYCLE));
}
export function goToFeedbackPage() {
    redirectToApp(R.FEEDBACK_USER);
}
export function goToMeetingDedicatedPage(meetingId) {
    redirectToApp(R.MEETING_DEDICATED, { meetingId });
}
export function goToMeetingPage(participantId, forceReload) {
    redirectToApp(R.MEETING_PAGE, { userIds: `${getCurrentUser().id}${participantId}` }, forceReload);
}
export function goToMeetingOverviewPage() {
    redirectToApp(R.MEETING_OVERVIEW);
}
export function goToSharedMeetingOverviewPage() {
    redirectToApp(R.YOUR_COMPANY_MEETINGS_SHARED);
}
export function goToMyMeetingTemplatesPage() {
    redirectToApp(R.YOUR_COMPANY_MEETINGS_MY_TEMPLATES);
}
export function goToDashboardPage() {
    redirectToApp(R.DASHBOARD);
}
export function goToOnboardingSurvey() {
    redirectToApp(R.COMPANY_ONBOARDING_DEMOGRAPHIC);
}
export function goToOnboardingSurveyInterestStep() {
    redirectToApp(R.COMPANY_ONBOARDING_INTEREST);
}
export function goToOnboardingSurveyHris() {
    redirectToApp(R.COMPANY_ONBOARDING_HRIS);
}
