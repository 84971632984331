import { getAllCycles as loadAllCycles } from '../../../api/custom/Cycles';
import { filter, includes, flow, map, forEach, sortBy, startsWith, escape } from 'lodash';
import { getUrl } from '../Cycle';
import { getCurrentUser } from '../CurrentUser';
import { matchOn, wordMatches, highlight } from './search-helper';
import replacement from '../../../react/services/Replacement';
import { PUBLIC_API_URL } from '../../../../app-config/constants';
const cyclesTTLInSeconds = 60;
let allCycles = Promise.resolve({
    cycles: [],
    timestamp: 0,
});
export function searchForCycles(searchResult) {
    return getAllCycles()
        .then(cyclesStore => {
        const cycles = cyclesStore.cycles;
        const query = searchResult.query;
        const foundCycles = flow(removeDisabled, search(query), sortByDate, toSearchItem, highlightQuery(query.trim()), addSearchItemProps, withScore(query))(cycles);
        return Object.assign(Object.assign({}, searchResult), { result: [...searchResult.result, ...foundCycles] });
    })
        .catch(() => searchResult);
}
function removeDisabled(cycles) {
    const enabledFeatures = getCurrentUser().company.enabledFeatures;
    if (!enabledFeatures.objective) {
        cycles = filter(cycles, c => c.type !== 'OBJECTIVE');
    }
    if (!enabledFeatures.feedback) {
        cycles = filter(cycles, c => c.type !== 'FEEDBACK');
    }
    if (!enabledFeatures.review) {
        cycles = filter(cycles, c => c.type !== 'REVIEW');
    }
    return cycles;
}
function search(query) {
    return cycles => {
        return filter(cycles, cycle => includes(cycle.name.toLowerCase(), query.toLowerCase()));
    };
}
function sortByDate(cycles) {
    return sortBy(cycles, x => -x.processStarts);
}
function toSearchItem(cycles) {
    return map(cycles, x => ({
        data: x,
    }));
}
function addSearchItemProps(cycles) {
    return forEach(cycles, cycle => {
        cycle.data.active = true;
        cycle.data.logoUrl = getLogo(cycle.data.type);
        cycle.typeFormatted = getLabel(cycle.data.type);
        cycle.type = cycle.data.type;
        cycle.url = getUrl(getCurrentUser(), cycle.data);
        cycle.id = cycle.data.id;
    });
}
function withScore(query) {
    return cycles => {
        return map(cycles, addScore(query));
    };
}
function addScore(query) {
    return cycle => {
        cycle.score = 0;
        if (matchOn('name', cycle, query)) {
            cycle.score += 0.1;
        }
        if (wordMatches('name', cycle, query)) {
            cycle.score += 0.2;
        }
        if (cycle.data.active === true) {
            cycle.score += 0.1;
        }
        return cycle;
    };
}
function highlightQuery(query) {
    return cycles => {
        return map(cycles, addHighlightedStrings(query));
    };
}
function addHighlightedStrings(query) {
    return cycle => {
        return Object.assign(Object.assign({}, cycle), { formattedValue: highlight(escape(cycle.data.name), query), plainValue: cycle.data.name });
    };
}
function getAllCycles() {
    return allCycles.then(cyclesStore => {
        const ageOfCacheInMs = Date.now() - cyclesStore.timestamp;
        const cacheExpired = ageOfCacheInMs > cyclesTTLInSeconds * 1000;
        if (cacheExpired) {
            return updateAllCycles();
        }
        else {
            return allCycles;
        }
    });
}
function updateAllCycles() {
    allCycles = loadAllCycles().then(cycles => ({
        cycles,
        timestamp: Date.now(),
    }));
    return allCycles;
}
function getLabel(type) {
    switch (type) {
        case 'REVIEW':
            return replacement('pr.singular.short') + ' Cycle';
        case 'OBJECTIVE':
            return replacement('objective.singular.big') + ' Cycle';
        case 'FEEDBACK':
            return replacement('fr.short.big') + ' Cycle';
    }
}
function getLogo(type) {
    switch (type) {
        case 'REVIEW':
            return (`${PUBLIC_API_URL}/avatars/16/` + getLetter(replacement('pr.singular.short')).substring(0, 1) + '.svg');
        case 'OBJECTIVE':
            return (`${PUBLIC_API_URL}/avatars/10/` +
                getLetter(replacement('objective.singular.big')).substring(0, 1) +
                '.svg');
        case 'FEEDBACK':
            return `${PUBLIC_API_URL}/avatars/4/` + getLetter(replacement('fr.short.big')) + '.svg';
    }
}
function getLetter(text) {
    if (text.length === 0) {
        return '?';
    }
    if (startsWith(text, '360')) {
        return 'F';
    }
    else {
        return text.substring(0, 1).toUpperCase();
    }
}
