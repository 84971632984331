import { takeEvery, delay } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { actionCreators } from '../../ducks';
import { apiException, ALL_REGISTERED_EXCEPTIONS } from '../../../ErrorProvider';
const POLLING_LIMIT = 5;
export function createCreateWatch(opts) {
    return function* watchCycleCreate() {
        yield* takeEvery(opts.watch, doCreate(opts, createUpdatePollStatus(opts)));
    };
}
function getReviewFeedbackPoll(willGetInformed, cycleHasStarted, revieweesToCreate, informManagers, informContributingReviewers, informReviewees) {
    return {
        revieweesCount: revieweesToCreate,
        revieweesToCreate,
        willGetInformed,
        createPending: true,
        invitePending: true,
        informPending: willGetInformed,
        noOneToCreate: revieweesToCreate === 0,
        cycleHasStarted,
        informManagers,
        informContributingReviewers,
        informReviewees,
    };
}
function getObjectivePoll(willGetInformed, cycleHasStarted) {
    return {
        willGetInformed,
        cycleHasStarted,
        informPending: willGetInformed,
        noOneToCreate: !willGetInformed,
    };
}
function doCreate({ actions, requests, willInform, informManagersFn, contributingReviewersFn, informRevieweesFn }, updatePollStatus) {
    return function* create(action) {
        const { payload } = action;
        const informManagers = informManagersFn ? informManagersFn(payload) : null;
        const informContributingReviewers = contributingReviewersFn ? contributingReviewersFn(payload) : null;
        const informReviewees = informRevieweesFn ? informRevieweesFn(payload) : null;
        const { model, cycleHasStarted } = payload;
        const revieweesToCreate = model.reviewees ? model.reviewees.length : 0;
        const willGetInformed = willInform(payload);
        const pollStatus = requests.poll
            ? getReviewFeedbackPoll(willGetInformed, cycleHasStarted, revieweesToCreate, informManagers, informContributingReviewers, informReviewees)
            : getObjectivePoll(willGetInformed, cycleHasStarted);
        yield put(actionCreators.doSetPollStatus(pollStatus));
        try {
            const cycle = yield call(requests.create, payload);
            yield put(actions.saveSuccess(cycle));
            if (requests.poll) {
                const initPollObj = {
                    id: cycle.id,
                    revieweesCount: model.reviewees.length,
                    willGetInformed,
                    cycleHasStarted,
                    informManagers,
                    informReviewees,
                    informContributingReviewers,
                };
                yield call(updatePollStatus, initPollObj);
            }
            else {
                yield call(fakeInformPendingStatus, willGetInformed);
            }
        }
        catch (error) {
            yield put(apiException.actionCreators.doErrorSet(ALL_REGISTERED_EXCEPTIONS.CYCLE_CREATE, error));
        }
        yield put(actionCreators.doSave());
    };
}
function createUpdatePollStatus({ requests }) {
    return function* updatePollStatus(pollObj) {
        const { id, revieweesCount, willGetInformed, cycleHasStarted, informManagers, informContributingReviewers, informReviewees, } = pollObj;
        const { doSetPollStatus } = actionCreators;
        let revieweesToCreate = revieweesCount;
        let retries = 0;
        let pollStatus = {
            revieweesCount,
            createPending: false,
            revieweesToCreate,
            willGetInformed,
            cycleHasStarted,
            informManagers,
            informContributingReviewers,
            informReviewees,
        };
        yield put(doSetPollStatus(pollStatus));
        while (getIsPolling(revieweesToCreate)) {
            yield delay(3000);
            const items = yield call(requests.poll, id);
            const newCount = revieweesCount - (Number.isInteger(items) ? items : items.length);
            // This is a bit of a hack, so that people do not get stuck, when they
            // encounter one of our oldest bugs.
            // Polling for reviewees created sometimes just hangs and will give you
            // back the exact same count, even when more reviewees have been
            // successfully created.
            // We just retry a couple of times in case we always get the same count,
            // and after a number of times we just give say we're done for now, so
            // that the user can at least continue...
            retries = newCount === revieweesToCreate ? retries + 1 : 0;
            const invitePending = retries < POLLING_LIMIT && getIsPolling(newCount);
            revieweesToCreate = newCount;
            yield put(doSetPollStatus({
                revieweesToCreate,
                revieweesCount,
                cycleHasStarted,
                informManagers,
                informContributingReviewers,
                informReviewees,
                invitePending,
            }));
        }
        yield call(fakeInformPendingStatus, willGetInformed);
    };
}
function* fakeInformPendingStatus(willGetInformed) {
    if (!willGetInformed) {
        return;
    }
    yield delay(1000);
    yield put(actionCreators.doSetPollStatus({
        informPending: false,
    }));
}
function getIsPolling(count) {
    return count > 0;
}
export function createEditWatch(opts) {
    return function* watchCycleCreate() {
        yield* takeEvery(opts.watch, doEdit(opts));
    };
}
function doEdit({ actions, requests, onDone, postSave }) {
    return function* edit(action) {
        const { model } = action.payload;
        model.reviewees = undefined;
        try {
            const cycle = yield call(requests.update, model);
            if (postSave) {
                yield call(postSave, cycle);
            }
            yield put(actions.saveSuccess(cycle));
            onDone();
        }
        catch (error) {
            yield put(apiException.actionCreators.doErrorSet(ALL_REGISTERED_EXCEPTIONS.CYCLE_EDIT, error));
        }
    };
}
