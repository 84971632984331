import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useEffect } from 'react';
import * as React from 'react';
import history from '../../../routing/history';
import { interpolateAppUrl } from '../../services/Url';
import LinkButton from '../LinkButton';
import styles from './styles.m.less';
export function SelectButton(_a) {
    var { route, title, description, icon: Icon, onClose, color } = _a, passedProps = __rest(_a, ["route", "title", "description", "icon", "onClose", "color"]);
    useEffect(() => {
        if (history) {
            return history.listen(() => onClose());
        }
    }, [onClose]);
    return (_jsxs(LinkButton, Object.assign({ href: interpolateAppUrl(route, {}), kind: "PLAIN", className: styles.selectButton }, passedProps, { children: [_jsx(Icon, { className: classNames(styles.icon, [color === 'TURQUOISE' ? styles.turquoise : styles.salmon]) }), _jsx("div", { className: styles.title, children: title }), _jsx("div", { className: styles.description, children: description })] })));
}
