import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useEffect } from 'react';
import { compose, map } from 'lodash/fp';
import { useAsync } from 'react-async-hook';
import { useHistory } from 'react-router';
import HorizontalRadio from '../../../components/HorizontalRadio';
import { mapProps } from '../../../hoc/recompose';
import { withData } from '../../../hoc/withData';
import { useAppContext } from '../../../hooks/useAppContext';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { isAnyAdmin } from '../../../services/User';
import { PureNavLink } from './PureNavLink';
import { adminLinks, employeeLinks } from './links';
import { isMatching, toView, useNavigation } from './service';
import styles from './style.m.less';
const withStatusLabel = C => props => {
    const { user } = useAppContext();
    const { result: statusLabel } = useAsync(() => { var _a; return ((_a = props.getStatusLabel) === null || _a === void 0 ? void 0 : _a.call(props, user)) || Promise.resolve(); }, [user]);
    return _jsx(C, Object.assign({}, props, { statusLabel: statusLabel }));
};
const withSubscriber = C => compose(withData({
    observe: {
        notification: props => props.notifier.observe(),
    },
    pendingComponent: C, // While pending, show component without the notification
    errorComponent: C, // If error happens for some reason, pretend that all is fine
}), mapProps(props => (Object.assign(Object.assign({}, props), { notification: props.notifier.toNotification(props.notification) }))), withStatusLabel)(C);
function BaseNavigation({ links }) {
    let i = 0;
    return (_jsx("nav", { role: "navigation", children: map(group => (_jsx(Group, { className: group.className, children: map(link => {
                const NavLink = link.notifier ? withSubscriber(PureNavLink) : withStatusLabel(PureNavLink);
                return _createElement(NavLink, Object.assign({}, link, { key: link.route + JSON.stringify(link.routeSearchParams) }));
            }, group.links) }, i++)), links) }));
}
function Group({ children, className }) {
    return (_jsx("ul", { role: "presentation", className: classNames(styles.group, className), children: children }));
}
const adminValue = 'admin';
const employeeValue = 'employee';
const radioOptions = [
    {
        label: 'Employee',
        value: employeeValue,
    },
    {
        label: 'Admin',
        value: adminValue,
    },
];
function getCurrentNav(previousNav, user, currentLocation) {
    if (!isAnyAdmin(user)) {
        return employeeValue;
    }
    else if (previousNav === employeeValue || previousNav === adminValue) {
        return previousNav;
    }
    else if (adminLinks.some(group => group.links.some(link => isMatching(currentLocation, link)))) {
        return adminValue;
    }
    else {
        return employeeValue;
    }
}
export function NewNavigation({ currentLocation, appContext }) {
    const { user } = useAppContext();
    const history = useHistory();
    const [previousNav, setPreviousNav] = useLocalStorage('nav-history', null);
    const currentNav = getCurrentNav(previousNav, user, currentLocation);
    const [selectedNav, setSelectedNav] = useNavigation(currentNav);
    useEffect(() => {
        if (history) {
            return history.listen((_location, action) => {
                if (action === 'POP') {
                    setPreviousNav(null);
                }
                else {
                    setPreviousNav(currentNav);
                }
            });
        }
    }, [history, setPreviousNav, currentNav]);
    const handleChangeSelectedNav = navValue => {
        setPreviousNav(navValue);
        setSelectedNav(navValue);
    };
    const links = selectedNav === employeeValue ? employeeLinks : adminLinks;
    const displayGroupedLinks = toView(links, appContext.user, currentLocation);
    return (_jsxs(_Fragment, { children: [isAnyAdmin(user) && (_jsx(HorizontalRadio, { small: false, name: "navigation", className: styles.navSelect, options: radioOptions, value: selectedNav, onChange: handleChangeSelectedNav })), _jsx(BaseNavigation, { links: displayGroupedLinks })] }));
}
