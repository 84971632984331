import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { size, filter, map, reduce } from 'lodash/fp';
import * as genderService from '../../../../../react/services/Gender';
import r from '../../../../../react/services/Replacement';
import * as urlBuilder from '../../../../../common/services/url-builder';
import { getCurrentUser } from '../../../../services/CurrentUser';
import UserLink from '../../../../components/UserLink';
export function transformMessageTargets({ targets }) {
    if (size(targets) > 0) {
        const transformedTargets = transformTargets({ targets });
        return _jsxs("span", { children: ["about ", transformedTargets] });
    }
    return _jsx("noscript", {});
}
export function transformPraiseTargets({ targets }) {
    if (size(targets) > 0) {
        const transformedTargets = transformTargets({ targets });
        return _jsxs("span", { children: ["to ", transformedTargets] });
    }
    return _jsx("noscript", {});
}
export function transformTargets({ targets }) {
    const sizeTargets = size(targets);
    if (sizeTargets === 1) {
        const user = targets[0];
        return _jsx(UserLink, { user: user });
    }
    if (sizeTargets === 2) {
        const user1 = targets[0];
        const user2 = targets[1];
        return (_jsxs("span", { children: [_jsx(UserLink, { user: user1 }), " and ", _jsx(UserLink, { user: user2 })] }));
    }
    if (sizeTargets > 2) {
        let user1 = targets[0];
        const count = sizeTargets - 1;
        const currentUser = getCurrentUser() || {};
        const res = findUser(targets, currentUser);
        if (size(res) === 1) {
            user1 = res[0];
        }
        return (_jsxs("span", { children: [_jsx(UserLink, { user: user1 }), " and ", count, " other people"] }));
    }
    return _jsx("noscript", {});
}
export function transformTarget(activityItem) {
    const { target } = activityItem;
    return _jsx(UserLink, { user: target });
}
export function transformActorName(actor) {
    if (!actor) {
        return _jsx("span", { children: "An anonymous user" });
    }
    const { name } = actor;
    // @todo don't use strong here!
    return _jsx("strong", { children: name });
}
export function countActivities({ items }) {
    return reduce((accumulator, value) => accumulator + value, 0, map(({ activities }) => activities.length, items));
}
function findUser(targets, user) {
    return filter(target => target.id === user.id, targets);
}
function isPossessivePronoun(value) {
    return value === 'his' || value === 'her';
}
export function transformObjectiveOwner({ actor, content }) {
    const { objective } = content;
    let user = Object.assign({ displayedName: transformOwner(objective, actor) }, objective.owner);
    if (isPossessivePronoun(user.displayedName)) {
        return _jsx("span", { children: user.displayedName });
    }
    if (!isCurrentUser(objective.owner)) {
        user.link = urlBuilder.linkToNewUserProfile(objective.owner.id);
    }
    return _jsx(UserLink, { user: user });
}
export function transformOwnerLink(owner) {
    const link = !isCurrentUser(owner) ? urlBuilder.linkToNewUserProfile(owner.id) : null;
    const user = {
        displayedName: transformOwner({ owner }),
        link,
    };
    return _jsx(UserLink, { user: user });
}
export function transformOwner({ owner }, actor) {
    if (isCurrentUser(owner)) {
        return 'your';
    }
    if (actor && owner && actor.id === owner.id && owner.gender) {
        return genderService.asPossessivePronoun(owner.gender.toLowerCase());
    }
    return genderService.asGenitive(owner.name || owner.firstName);
}
export function transformChecked({ change }) {
    return change.newValue === 'true' ? 'checked' : 'unchecked';
}
export function transformObjectiveContentTitle({ content }) {
    const kind = content.content.kind;
    if (kind === 'KEY_RESULTS') {
        return `${r('objective.article.key-result')} ${r('objective.singular.key-result')}`;
    }
    if (kind === 'ACTIONS') {
        return 'an Action Item';
    }
    if (kind === 'NEEDS') {
        return 'a Need';
    }
    return `${r('objective.article.key-result')} ${r('objective.singular.key-result')}`;
}
export function findChecklistChange({ type, change }) {
    if (type === 'OBJECTIVE_CHECKLIST_TOGGLED') {
        return change.newValue === 'true' ? 'checked' : 'unchecked';
    }
    if (type === 'OBJECTIVE_LIST_ITEM_ADDED') {
        return 'added';
    }
    if (type === 'OBJECTIVE_LIST_ITEM_REMOVED') {
        return 'removed';
    }
}
export function isCurrentUser(user) {
    const currentUser = getCurrentUser();
    return currentUser && currentUser.id === user.id;
}
