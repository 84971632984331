import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { includes } from 'lodash/fp';
import { useMemo } from 'react';
import * as React from 'react';
import API from '../../../../../api';
import * as R from '../../../../../routes';
import { getAdminRouteForNavigation } from '../../../../../routes-pairs';
import RouteError from '../../../../../routing/RouteError/index';
import AdminHelpList from '../../../../components/AdminHelpList';
import Button from '../../../../components/Button';
import PageTitle from '../../../../components/PageTitle';
import triggerPopupAlert from '../../../../components/PopupAlert/trigger';
import Redirect from '../../../../components/Redirect';
import { Container } from '../../../../components/Zen/Container';
import { ErrorMessage } from '../../../../components/Zen/ErrorMessage';
import { handleApiErrors, withData } from '../../../../hoc/withData';
import withModal from '../../../../hoc/withModal';
import { useAppContext } from '../../../../hooks/useAppContext';
import r from '../../../../services/Replacement';
import { goToUrl } from '../../../../services/Router';
import { trackEvent } from '../../../../services/Tracking';
import { interpolateAppUrl, prependAppPath } from '../../../../services/Url';
import { ShareAlert } from '../../../Feedback/components/ShareAlert';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { getProvideWordings } from '../../services/wording';
const api = API.cycleFeedbackResponse;
const ButtonWithModal = withModal(Button);
const DEFAULT_CLOSE_PATH = prependAppPath(R.FEEDBACK_USER) + '?tab=todo';
const FeedbackZenProvideContainer = ({ component: FeedbackZenProvide, response, prevPath, }) => {
    const actions = useMemo(() => ({
        updateAnswers: answers => api.answer(response.id, answers),
        share: () => api.share(response.id).then(res => {
            trackEvent({ category: '360_PROVIDE_SCREEN_V2', label: 'Share feedback' });
            triggerPopupAlert(_jsx(ShareAlert, { response: res }), 'success', { surviveNavigation: true });
            return res;
        }),
        close: () => {
            trackEvent({ category: '360_PROVIDE_SCREEN_V2', label: 'Save and close' });
            goToUrl(prevPath || DEFAULT_CLOSE_PATH);
        },
    }), [prevPath, response.id]);
    const wordings = getProvideWordings(response);
    return (_jsxs(_Fragment, { children: [response.status === 'WRITTEN' && (_jsx(Redirect, { to: interpolateAppUrl(R.FEEDBACK_USER_VIEW_MULTIPLE, { id: response.requestId }) })), _jsx(PageTitle, { title: wordings.pageTitle }), _jsx(Container, { children: _jsx(FeedbackZenProvide, { response: response, actions: actions }) })] }));
};
export const individualizedErrors = {
    FEEDBACK_CYCLE_DEADLINE_PASSED: ({ error }) => {
        const appContext = useAppContext();
        const isCurrentUserAnAdmin = includes(appContext.user.id, error.adminsToContact);
        return (_jsx(ErrorMessage, { illustrationSrc: require('../../../Feedback/feedback_deadline_passed.svg'), title: "Deadline Passed", body: isCurrentUserAnAdmin ? (_jsxs("p", { children: ["If you need more time to complete this ", r('fr.singular'), ", please extend the timeline in this", ' ', _jsx("a", { href: interpolateAppUrl(getAdminRouteForNavigation(R.FEEDBACK_CYCLE_EDIT_NEW), {
                            cycleId: error.cycleId,
                        }), children: "cycle's settings" }), ' ', "first."] })) : (_jsxs(_Fragment, { children: [_jsxs("p", { children: ["Please ask your cycle admins to extend the timeline for this ", r('fr.singular'), " period if you need more time to complete yours."] }), _jsx("div", { className: "button-container", children: _jsx(ButtonWithModal, { modalProps: {
                                body: () => (_jsx(AdminHelpList, { adminIds: error.adminsToContact, children: () => (_jsxs(_Fragment, { children: [_jsx("h1", { children: "View Admins" }), ' '] })) })),
                            }, children: "View Admins" }) })] })) }));
    },
    FEEDBACK_REQUEST_NOT_IN_PROGRESS: ({ error }) => {
        const appContext = useAppContext();
        const isCurrentUserAnAdmin = includes(appContext.user.id, error.adminsToContact);
        return (_jsx(ErrorMessage, { illustrationSrc: require('../../../Feedback/feedback_deadline_passed.svg'), title: "Deadline Passed", body: isCurrentUserAnAdmin ? (_jsxs("p", { children: ["If you'd like to contribute and share your feedback, please re-open the", ' ', r('fr.review.short'), " in this", ' ', _jsx("a", { href: interpolateAppUrl(getAdminRouteForNavigation(R.YOUR_COMPANY_FEEDBACK_WITH_CYCLE), {
                            cycleId: error.cycleId,
                        }), children: "cycle's overview page" }), "."] })) : (_jsxs(_Fragment, { children: [_jsxs("p", { children: ["A ", r('manager'), " or ", r('general.hr'), " admin has already marked this reviewee\u2019s", ' ', r('fr.singular'), " complete."] }), _jsxs("p", { children: ["Please ask your cycle admins to re-open the ", r('fr.review.short'), " if you\u2019d like to contribute and share your feedback."] }), _jsx("div", { className: "button-container", children: _jsx(ButtonWithModal, { modalProps: {
                                body: () => (_jsx(AdminHelpList, { adminIds: error.adminsToContact, children: () => (_jsxs(_Fragment, { children: [_jsx("h1", { children: "View Admins" }), ' '] })) })),
                            }, children: "View Admins" }) })] })) }));
    },
};
export const errorComponent = handleApiErrors(individualizedErrors, RouteError);
export default withData({
    observe: {
        response: ({ responseId }) => api.get.createObservable(responseId),
    },
    resolve: {
        component: () => import(/* webpackChunkName: 'FeedbackProvideZen' */ './presenter').then(({ FeedbackZenProvide }) => FeedbackZenProvide),
    },
    pendingComponent: LoadingIndicator,
    errorComponent,
})(FeedbackZenProvideContainer);
