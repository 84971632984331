import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconShoppingCart } from 'featherico';
import * as React from 'react';
import Button from '../../../../components/Button';
import { goToUrl } from '../../../../services/Router';
import { hasBasicHr, isManager } from '../../../../services/User';
import { getAdminBillingUrl } from '../../../Billing/services/status';
const BuyButton = ({ user }) => {
    const { payment } = user.company;
    const { plan, userCount, freeLimit } = payment;
    if ((hasBasicHr(user) || isCEO(user)) && plan === 0 && userCount > freeLimit) {
        const buy = () => {
            getAdminBillingUrl(user).then(goToUrl);
        };
        return (_jsx("div", { className: "app-header-button-content", children: _jsxs(Button, { kind: "PRIMARY", isSmall: true, onClick: buy, eventTracking: { category: 'navigation header', action: 'buy' }, children: [_jsx(IconShoppingCart, {}), " ", _jsx("span", { className: "app-header-item-label", children: "Buy" })] }) }));
    }
    return null;
};
export default BuyButton;
function isCEO(user) {
    return !user.isGuest && isManager(user) && !user.managerId;
}
