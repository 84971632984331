import { get } from '../request';
import { API_URL } from '../../../app-config/constants';
const RESOURCE = `${API_URL}/cycles`;
getAllCycles.operation = 'READ';
export function getAllCycles() {
    return get(RESOURCE + '/all');
}
getLatest.operation = 'READ';
export function getLatest(numberToGet) {
    return get(RESOURCE + '/recent?amount=' + numberToGet);
}
