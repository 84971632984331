// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VerticalRadio-verticalRadio-3b1-I {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  font-size: 15px;\n  padding-bottom: calc(20px*2);\n  --fi-size: 18px;\n}\n@media (max-width: 800px) {\n  .VerticalRadio-verticalRadio-3b1-I {\n    width: 90vw;\n  }\n}\n.VerticalRadio-verticalRadio-3b1-I input {\n  border: none;\n  position: absolute;\n  clip: rect(0 0 0 0);\n  width: 1px;\n  height: 1px;\n  margin: -1px;\n  overflow: hidden;\n  padding: 0;\n}\n.VerticalRadio-verticalRadio-3b1-I input:checked + label,\n.VerticalRadio-verticalRadio-3b1-I input:checked + label:hover {\n  background: #edeceb;\n  border-radius: 4px;\n}\n.VerticalRadio-verticalRadio-3b1-I input:not(:checked) + label:not([disabled]) {\n  cursor: pointer;\n}\n.VerticalRadio-verticalRadio-3b1-I input:not(:checked) + label:not([disabled]):hover,\n.VerticalRadio-verticalRadio-3b1-I input:not(:checked) + label:not([disabled]):focus {\n  border-radius: 4px;\n  background-color: #edeceb;\n}\n.VerticalRadio-verticalRadio-3b1-I label {\n  display: inline-block;\n  white-space: nowrap;\n  background: #ffffff;\n  flex: 1 auto;\n  text-align: center;\n  padding: 20px calc(20px*6);\n  border-radius: 4px;\n  border: 1px solid #a3a2a1;\n}\n@media (max-width: 800px) {\n  .VerticalRadio-verticalRadio-3b1-I label {\n    padding: 20px;\n  }\n}\n.VerticalRadio-verticalRadio-3b1-I.VerticalRadio-small-2bPzB {\n  height: 28px;\n  padding: 2px;\n}\n.VerticalRadio-verticalRadio-3b1-I.VerticalRadio-small-2bPzB label {\n  height: 22px;\n}\n.VerticalRadio-regularLabel-2F-UE {\n  font-size: 15px;\n  --fi-size: 18px;\n}\n.VerticalRadio-smallLabel-hsZUP {\n  font-size: 13px;\n  --fi-size: 14px;\n}\n", ""]);
// Exports
exports.locals = {
	"verticalRadio": "VerticalRadio-verticalRadio-3b1-I",
	"small": "VerticalRadio-small-2bPzB",
	"regularLabel": "VerticalRadio-regularLabel-2F-UE",
	"smallLabel": "VerticalRadio-smallLabel-hsZUP"
};
module.exports = exports;
