import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import * as React from 'react';
import API from '../../../../../api';
import * as R from '../../../../../routes';
import RouteError from '../../../../../routing/RouteError';
import triggerPopupAlert from '../../../../components/PopupAlert/trigger';
import Redirect from '../../../../components/Redirect';
import { ErrorMessage } from '../../../../components/Zen/ErrorMessage';
import { handleApiErrors, withData } from '../../../../hoc/withData';
import { isFeatureEnabled } from '../../../../services/Features';
import { goToUrl } from '../../../../services/Router';
import { interpolateAppUrl, prependAppPath } from '../../../../services/Url';
import { ShareAlert } from '../../../Feedback/components/ShareAlert';
import { LoadingIndicator } from '../../components/Provide/LoadingIndicator';
const api = API.anytimeFeedbackResponse;
const errorComponent = handleApiErrors({
    DEADLINE_PASSED: () => (_jsx(ErrorMessage, { illustrationSrc: require('../../../Feedback/feedback_deadline_passed.svg'), title: "Deadline Passed", body: _jsx("p", { children: isFeatureEnabled('FEATURE_anytimeFeedbackGive')
                ? 'The time to write your feedback is over.'
                : 'The time to answer this feedback request is over.' }) })),
}, RouteError);
const DEFAULT_CLOSE_PATH = prependAppPath(R.FEEDBACK_USER) + '?tab=todo';
const AnytimeFeedbackProvideContainer = ({ response, prevPath, component: AnytimeFeedbackProvide, }) => {
    const actions = useMemo(() => ({
        updateAnswers: answers => api.answer(response.requestId, response.reviewer.id, answers),
        share: () => api.share(response.requestId, response.reviewer.id).then(res => {
            triggerPopupAlert(_jsx(ShareAlert, { response: res }), 'success', { surviveNavigation: true });
            return res;
        }),
        close: () => goToUrl(prevPath || DEFAULT_CLOSE_PATH),
    }), [prevPath, response.requestId, response.reviewer.id]);
    return (_jsxs(_Fragment, { children: [(response.status === 'DONE' || response.status === 'SHARED') && (_jsx(Redirect, { to: interpolateAppUrl(R.FEEDBACK_USER_VIEW_MULTIPLE, { id: response.requestId }) })), _jsx(AnytimeFeedbackProvide, { response: response, actions: actions })] }));
};
export default withData({
    resolve: {
        component: () => import(/* webpackChunkName: 'AnytimeFeedbackProvide' */ './presenter').then(({ AnytimeFeedbackProvide }) => AnytimeFeedbackProvide),
    },
    observe: {
        response: ({ requestId, reviewerId }) => api.get.createObservable(requestId, reviewerId),
    },
    pendingComponent: LoadingIndicator,
    errorComponent,
})(AnytimeFeedbackProvideContainer);
