import { jsx as _jsx } from "react/jsx-runtime";
import { find, includes } from 'lodash/fp';
import { Children } from 'react';
import { matchPath, Redirect, useLocation } from 'react-router';
import RouteError from '../../../routing/RouteError';
import { getCurrentUser } from '../../services/CurrentUser';
import { sessionStorage } from '../../services/SafeBrowserStorage';
export default function SwitchDefaultRoute({ children, defaultPath, excludedPaths = [] }) {
    const location = useLocation();
    const key = getKey(defaultPath);
    const childArray = Children.toArray(children);
    if (matchPath(location.pathname, { path: defaultPath, exact: true })) {
        const lastPath = sessionStorage.getItem(key);
        const validLastPath = childArray.some(child => child.props.path === lastPath) ? lastPath : undefined;
        const redirectPath = validLastPath || childArray[0].props.path;
        return _jsx(Redirect, { to: redirectPath });
    }
    const result = find(element => {
        const { path, exact, strict } = element.props;
        return matchPath(location.pathname, { path, exact, strict });
    })(childArray);
    if (result) {
        const { path } = result.props;
        if (!includes(':', path) && !excludedPaths.includes(path)) {
            sessionStorage.setItem(key, path);
        }
        return result;
    }
    sessionStorage.removeItem(key);
    return _jsx(RouteError, { errorCode: 404 });
}
function getKey(path) {
    return 'SwitchDefaultRoute_' + getCurrentUser().id + '_' + path;
}
