import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import EntityPreview from '../EntityPreview';
import { FeedbackSingleIcon, PrivateNoteIcon } from '../icons';
import PopupAlertContent from '../PopupAlertContent';
const MessageIcons = {
    PRIVATE_NOTES: PrivateNoteIcon,
    PEER_FEEDBACK: FeedbackSingleIcon,
};
export function convertRichText(text) {
    const domNode = document.createElement('div');
    domNode.innerHTML = text;
    return domNode.textContent;
}
export default function MessageAlert({ project, message, entityName }) {
    const title = `${entityName} Created`;
    const href = `/app/messages/${message.id}`;
    const Icon = MessageIcons[project.projectType] || project.iconComponent;
    const body = message.message && convertRichText(message.message);
    return (_jsx(PopupAlertContent, { title: title, href: href, icon: _jsx(Icon, {}), children: _jsx(EntityPreview, { title: message.title || body, subtitle: message.title ? body : '' }) }));
}
MessageAlert.propTypes = {
    message: PropTypes.shape({
        title: PropTypes.string,
        message: PropTypes.string,
    }).isRequired,
    project: PropTypes.shape({
        projectType: PropTypes.string,
        iconComponent: PropTypes.any,
    }).isRequired,
    entityName: PropTypes.string.isRequired,
};
