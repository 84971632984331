import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CycleCreateContent } from './presenter';
import { actionCreators, selectors } from '../ducks';
function mapStateToProps(state, props) {
    const { modelSelector, getValidationData, content, controls } = props;
    const currentStep = selectors.getCurrentStep(state);
    const editedSteps = selectors.getEditedSteps(state);
    const mode = selectors.getMode(state);
    const model = modelSelector(state);
    const validationData = getValidationData ? getValidationData(state) : null;
    return {
        currentStep,
        content,
        model,
        editedSteps,
        controls,
        validationData,
        mode,
    };
}
function mapDispatchToProps(d) {
    return {
        actions: Object.assign({}, bindActionCreators({
            enterEditMode: actionCreators.doEnterEditMode,
            leaveEditMode: actionCreators.doLeaveEditMode,
            next: actionCreators.doGoToNextSection,
        }, d)),
    };
}
export const MODES = {
    create: 'create',
    edit: 'edit',
    copy: 'copy',
};
export default connect(mapStateToProps, mapDispatchToProps)(CycleCreateContent);
