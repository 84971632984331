import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../ducks';
import { searchWithActions } from '../../../services/HeaderSearchBar';
import Navigation from './presenter';
function mapStateToProps(state) {
    return {
        mode: state.headerSearchBar.mode,
    };
}
function mapDispatchToProps(dispatch) {
    const actions = bindActionCreators({
        doSearch: actionCreators.doSearch,
        doSearchSuccess: actionCreators.doSearchSuccess,
        doSearchError: actionCreators.doSearchError,
        doSelectItem: actionCreators.doSelectItem,
    }, dispatch);
    return {
        doSelectItem: actions.doSelectItem,
        search: query => searchWithActions(query, actions.doSearch, actions.doSearchSuccess, actions.doSearchError),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
