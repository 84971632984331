import { jsx as _jsx } from "react/jsx-runtime";
import { compose, find, map } from 'lodash/fp';
import { Component } from 'react';
import { createPortal } from 'react-dom';
import { matchPath, Switch } from 'react-router';
import { CSSTransition, Transition, TransitionGroup } from 'react-transition-group';
import { BASE } from '../../routes';
import ErrorBoundary from '../ErrorBoundary';
import PageRoute from '../PageRoute';
import RouteError from '../RouteError';
import styles from './style.m.less';
const zenModeOpenBodyClassName = 'FullScreenRoute--open';
const timeout = 300;
const defaultTransitionProps = { timeout, unmountOnExit: true };
const classNames = {
    enter: styles.zenWrapperHidden,
    enterActive: styles.zenWrapperEntering,
    enterDone: styles.zenWrapperEntered,
    exitActive: styles.zenWrapperHidden,
    exitDone: styles.zenWrapperHidden,
};
const findMatch = find(({ match }) => !!match);
const zenRoot = document.getElementById('full-screen-root');
const mainWrapper = document.getElementsByClassName('main-wrapper')[0];
const onZenEntered = () => {
    mainWrapper.setAttribute('aria-hidden', 'true');
    mainWrapper.classList.add(styles.mainWrapperHidden);
    document.body.classList.add(zenModeOpenBodyClassName);
};
const onZenExit = () => {
    mainWrapper.removeAttribute('aria-hidden');
    mainWrapper.classList.remove(styles.mainWrapperHidden);
    document.body.classList.remove(zenModeOpenBodyClassName);
};
const ZenWrapper = ({ children, in: inProp }) => createPortal(_jsx(CSSTransition, Object.assign({}, defaultTransitionProps, { appear: true, in: inProp, classNames: classNames, onEntered: onZenEntered, onExit: onZenExit, children: _jsx("main", { role: "main", className: styles.zenWrapper, children: children }) })), zenRoot);
export default function RoutesWrapper({ zenRoutes = [], children }) {
    return (_jsx(PageRoute, { render: ({ location, history }) => (_jsx(RoutesWrapperInner, { location: location, zenRoutes: zenRoutes, action: history.action, children: children })) }));
}
class RoutesWrapperInner extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            curPath: this.props.location.pathname + this.props.location.search,
            prevPath: undefined,
        };
    }
    static getDerivedStateFromProps({ location: { pathname, search }, action }, { curPath, prevPath }) {
        const fullLocation = pathname + search;
        if (action === 'REPLACE') {
            return { prevPath, curPath: fullLocation };
        }
        return curPath !== fullLocation
            ? {
                prevPath: curPath,
                curPath: fullLocation,
            }
            : null;
    }
    render() {
        const { location, zenRoutes, children } = this.props;
        const { prevPath } = this.state;
        const zenRoute = compose(findMatch, map(([path, render]) => ({ render, match: matchPath(location.pathname, { path, exact: true }) })))(zenRoutes);
        return (_jsx(TransitionGroup, { component: null, children: zenRoute ? (_jsx(ZenWrapper, { children: _jsx(ErrorBoundary, { errorComponent: RouteError, children: zenRoute.render({
                        location,
                        match: zenRoute.match,
                        prevPath: prevPath && BASE + prevPath,
                    }) }) }, "zen")) : (_jsx(Transition, Object.assign({}, defaultTransitionProps, { children: _jsx(Switch, { location: location, children: children }) }), "regular")) }));
    }
}
