import moment from 'moment';
import { getOr } from 'lodash/fp';
import replacement from '../../../../../react/services/Replacement';
import { convertTimestampInTimeZone } from '../../../../../common/services/date-helper/service';
import { getCurrentUser } from '../../../../services/CurrentUser';
import { getCurrentQuarter, getLastQuarter } from '../Quarter';
const QUARTERS = {
    review: {
        fn: getNormalizedLastQuarter,
        moduleName: replacement('pr.singular.short'),
    },
    objective: {
        fn: getCurrentQuarter,
        moduleName: replacement('objective.singular.big'),
    },
    feedback: {
        fn: getNormalizedLastQuarter,
        moduleName: replacement('fr.singular.title'),
    },
};
const DEFAULT_FORMAT = 'MM/DD/YYYY';
const getFormat = () => getOr(DEFAULT_FORMAT, 'company.companySettings.dateFormatCoarse')(getCurrentUser());
export function getDefaultName(type) {
    const now = toCompanyTimeZone(moment());
    const quarter = QUARTERS[type].fn(now);
    return `${QUARTERS[type].moduleName} Cycle ${quarter.name}/${quarter.year}`;
}
export function getDefaultExplanationReview() {
    return `Please write your ${replacement('pr.assessment')} with a focus on this review period. Highlight your achievements and accomplishments. In what area(s) do you feel you could benefit from additional support or direction?
    Thank you for participating in the performance review process!`;
}
export function getDefaultExplanationFeedback() {
    const now = toCompanyTimeZone(moment());
    const quarter = QUARTERS.feedback.fn(now);
    return `Please provide feedback on the reviewee's performance between ${quarter.start.format(getFormat())} and ${quarter.end.format(getFormat())}.`;
}
export function getDefaultExplanationObjective() {
    return `Please reflect on what you’d like to accomplish during this time period. What outcomes can be measured to ensure that you’ll have reached those goals?`;
}
export function getDefaultUnderReview() {
    const now = toCompanyTimeZone(moment());
    const { start, end } = getLastQuarter(now);
    return { start: +start, end: +end };
}
export function getDefaultTimePeriod() {
    const now = toCompanyTimeZone(moment());
    const { start, end } = getCurrentQuarter(now);
    return { start: +start, end: +end };
}
function toCompanyTimeZone(timestamp) {
    const { companySettings } = getCurrentUser().company;
    const { endOfBusiness, timeZone } = companySettings;
    const timestampWithBusinessHours = moment(timestamp).startOf('day').add(endOfBusiness, 'hours');
    return convertTimestampInTimeZone(timestampWithBusinessHours, timeZone);
}
function getNormalizedLastQuarter(now) {
    // when we are at the end of a quarter, take the current one!
    const fn = (now.month() + 1) % 3 === 0 ? getCurrentQuarter : getLastQuarter;
    return fn(now);
}
