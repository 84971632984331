import { jsx as _jsx } from "react/jsx-runtime";
import { withData } from '../../../../../hoc/withData';
import { get } from '../../../../../../api/request';
import LoadingIndicatorFull from '../../../../../components/LoadingIndicatorFull';
import { getCopyModel } from '../../services';
import ObjectiveCycleCreate from '../../lazy';
import { setPropTypes, compose } from 'recompose';
import PropTypes from 'prop-types';
import { API_URL } from '../../../../../../../app-config/constants';
import api from '../../../../../../api';
function ObjectiveCycleCopyPage({ cycle, allUsers }) {
    const { model: backendModel } = cycle;
    const model = getCopyModel(Object.assign(Object.assign({}, backendModel), { allUsers }));
    return _jsx(ObjectiveCycleCreate, { mode: "copy", model: model });
}
const BASE = `${API_URL}/objective-cycles/guided`;
export default compose(setPropTypes({
    cycleId: PropTypes.string.isRequired,
}), withData({
    resolve: {
        cycle: ({ cycleId }) => get(`${BASE}/${cycleId}`),
        allUsers: () => api.usersMedium.getAll(),
    },
    pendingComponent: () => _jsx(LoadingIndicatorFull, {}),
}))(ObjectiveCycleCopyPage);
