import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import { IconBell } from 'featherico';
import Button from '../../Button';
import NotificationsDropdown from '../NotificationsDropdown';
import NotificationsCount from '../NotificationsCount';
import classNames from 'classnames';
import styles from './style.m.less';
const propTypes = {
    showDropdown: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
        doShowNotificationsDropdown: PropTypes.func.isRequired,
    }),
    count: PropTypes.number,
};
class NotificationsTrigger extends Component {
    constructor(props) {
        super(props);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleEscapeHit = this.handleEscapeHit.bind(this);
    }
    UNSAFE_componentWillMount() {
        if (this.props.showDropdown) {
            this.addEventListeners();
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const oldState = this.props.showDropdown;
        const newState = nextProps.showDropdown;
        if (newState !== oldState) {
            if (newState) {
                this.addEventListeners();
            }
            else {
                this.removeEventListeners();
            }
        }
    }
    componentWillUnmount() {
        this.removeEventListeners();
    }
    addEventListeners() {
        this.removeEventListeners();
        document.addEventListener('click', this.handleDocumentClick);
        document.addEventListener('keyup', this.handleEscapeHit);
    }
    removeEventListeners() {
        document.removeEventListener('click', this.handleDocumentClick);
        document.removeEventListener('keyup', this.handleEscapeHit);
    }
    handleDocumentClick(event) {
        if (this.props.showDropdown && !ReactDOM.findDOMNode(this).contains(event.target)) {
            this.props.actions.doShowNotificationsDropdown(false);
        }
    }
    handleEscapeHit(event) {
        if (this.props.showDropdown && event.keyCode === 27) {
            this.props.actions.doShowNotificationsDropdown(false);
        }
    }
    render() {
        const { showDropdown, actions } = this.props;
        return (_jsxs("span", { className: styles.container, children: [_jsx(NotificationsTriggerButton, Object.assign({}, this.props, { onClick: () => actions.doShowNotificationsDropdown(!showDropdown) })), showDropdown ? (_jsx(NotificationsDropdown, { onCloseClick: () => actions.doShowNotificationsDropdown(false) })) : null] }));
    }
}
NotificationsTrigger.propTypes = propTypes;
function NotificationsTriggerButton({ onClick, hasAlert, showDropdown, count }) {
    const buttonClasses = classNames('headerIconButton', {
        [styles.has_count]: hasAlert,
        [styles.dropdown_visible]: showDropdown,
    }, 'apc-app-header-notification');
    return (_jsxs(Button, { kind: "PLAIN", onClick: onClick, className: buttonClasses, "aria-label": "Notifications", "aria-haspopup": "true", "aria-expanded": showDropdown, eventTracking: { category: 'navigation header', action: 'click bell', value: count }, children: [_jsx(IconBell, { className: styles.bell }), _jsx(NotificationsCount, {})] }));
}
export default NotificationsTrigger;
