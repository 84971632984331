import { useEffect } from 'react';
import { useAppContext } from './useAppContext';
import { localStorage } from '../services/SafeBrowserStorage';
import { COMPANY_COLOR } from '../../design';
export function useCompanyColorCSSVariable() {
    const { user } = useAppContext();
    const tintColor = user.company.companyDesignSettings.companyColor;
    useEffect(() => {
        document.body.style.setProperty('--companyColor', tintColor);
        if (tintColor) {
            localStorage.setItem(COMPANY_COLOR, tintColor);
        }
        else {
            localStorage.removeItem(COMPANY_COLOR);
        }
    }, [tintColor]);
}
