import { jsx as _jsx } from "react/jsx-runtime";
import api from '../../../../../api';
import { withData } from '../../../../hoc/withData';
import { EMAIL_TEMPLATE_TYPES } from '../../constants/email-templates';
import ReviewCycleCreate from '../../lazy';
import { getCreateModel } from '../../services';
function ReviewCycleCreatePage({ users, emailTemplates }) {
    const model = getCreateModel(users, [], emailTemplates);
    return _jsx(ReviewCycleCreate, { mode: "create", model: model });
}
export default withData({
    resolve: {
        users: () => api.usersMedium.getAll(),
        emailTemplates: () => api.emailTemplate.getTemplates(EMAIL_TEMPLATE_TYPES),
    },
    pendingComponent: () => null,
})(ReviewCycleCreatePage);
