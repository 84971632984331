import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import Button from '../../../components/Button';
import HelpscoutSupportLink from './HelpscoutSupportLink';
import styles from './style.m.less';
import WithHrListSupportModal from './WithHrListSupportModal';
import { shouldEnableIntercom } from '../../../services/intercom';
import IntercomSupportLink from './IntercomSupportLink';
export default class SupportLink extends Component {
    render() {
        const openBeacon = window._currentUser.isAdmin || window._currentUser.isHR || window._currentUser.isAssistant;
        if (openBeacon) {
            return shouldEnableIntercom() ? _jsx(IntercomSupportLink, {}) : _jsx(HelpscoutSupportLink, {});
        }
        else {
            return (_jsx(WithHrListSupportModal, { children: ({ openModal }) => (_jsx(Button, { kind: "PLAIN", className: styles.hrListButton, onClick: openModal, eventTracking: {
                        category: 'navigation footer',
                        action: 'support needed',
                        label: 'modal',
                    }, children: "Need Support?" })) }));
        }
    }
}
