import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { withData } from '../../../../hoc/withData';
import LoadingIndicatorFull from '../../../../components/LoadingIndicatorFull';
import FeedbackCycleCreate from '../../lazy';
import { setPropTypes, compose } from 'recompose';
import PropTypes from 'prop-types';
import api from '../../../../../api';
import { EMAIL_TEMPLATE_TYPES } from '../../constants/email-templates';
import { isFeatureEnabled } from '../../../../services/Features';
import { loadFeedbackCycle, loadQuestionnaireTemplates } from '../../services/Request';
function FeedbackCycleCopyPage(_a) {
    var { cycle, questionnaireTemplates } = _a, rest = __rest(_a, ["cycle", "questionnaireTemplates"]);
    const { model: backendModel } = cycle;
    const model = Object.assign(Object.assign({}, backendModel), { name: `Copy of ${backendModel.name}` });
    return (_jsx(FeedbackCycleCreate, { mode: "copy", model: Object.assign(Object.assign({}, model), rest), questionnaireTemplates: questionnaireTemplates }));
}
export default compose(setPropTypes({
    cycleId: PropTypes.string.isRequired,
}), withData({
    resolve: {
        cycle: ({ cycleId }) => loadFeedbackCycle(cycleId),
        allUsers: () => api.usersMedium.getAll(),
        hrUsers: () => api.usersHR.getAllActiveHrs(true),
        questionnaireTemplates: () => isFeatureEnabled('FEATURE_feedbackQuestionnaireTemplates')
            ? loadQuestionnaireTemplates()
            : Promise.resolve([]),
        emailTemplates: ({ cycleId }) => api.emailTemplate.getTemplates(EMAIL_TEMPLATE_TYPES, { feedbackCycleId: cycleId }),
    },
    pendingComponent: () => _jsx(LoadingIndicatorFull, {}),
}))(FeedbackCycleCopyPage);
