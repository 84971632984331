import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { identity, includes, take } from 'lodash';
import { debounce } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import * as React from 'react';
import * as keyEventStack from '../..//services/KeyEventStack';
import { getNext, getPrevious } from '../../services/List';
import { AriaAnnouncer } from '../AriaHelper';
import Arrow from '../Arrow';
import Button from '../Button';
import Input from '../Input';
import styles from './style.m.less';
const EMPTY_ITEM = {};
const EMPTY_STYLE = {};
const MAX_CACHE_SIZE = 20;
export function Choice({ label, isHighlighted }) {
    return _jsx("div", { className: classNames(styles.choice, { [styles.isHighlighted]: isHighlighted }), children: label });
}
export function choiceWithLabel(choiceToLabel) {
    return (choice, isHighlighted) => (_jsx(Choice, { label: choiceToLabel(choice), isHighlighted: isHighlighted }));
}
class Autocomplete extends Component {
    constructor() {
        var _a;
        super(...arguments);
        this.state = {
            query: (_a = this.props.query) !== null && _a !== void 0 ? _a : '',
            showResults: false,
            results: [],
            highlightedResult: undefined,
            quickSelectMode: false,
        };
        this.cache = {
            dict: {},
            list: [],
        };
        this.keyEventStackCallback = {
            onKey: (event) => {
                if (keyEventStack.isEsc(event)) {
                    this.hideResults();
                    return false;
                }
            },
        };
        this.hasRegisteredKeyEventStackCallback = false;
        this.node = null;
        this.inputNode = null;
        this.initialRender = true;
        this.hideResults = () => {
            this.setState({ showResults: false, quickSelectMode: false, highlightedResult: undefined });
            this.hasRegisteredKeyEventStackCallback = false;
            keyEventStack.deregister(this.keyEventStackCallback);
        };
        this.interceptKeyboardHandling = (event, results) => {
            const { highlightedResult } = this.state;
            const actions = {
                13: {
                    // enter
                    prevent: true,
                    action: () => {
                        if (highlightedResult) {
                            this.onSelect(highlightedResult);
                        }
                    },
                },
                27: {
                    prevent: false,
                    action: () => this.hideResults(),
                },
                32: {
                    // space
                    prevent: false,
                    action: () => {
                        if (highlightedResult) {
                            this.onSelect(highlightedResult);
                        }
                    },
                },
                38: {
                    // up arrow
                    prevent: true,
                    action: () => this.highlightPrevious(results),
                },
                40: {
                    // down arrow
                    prevent: true,
                    action: () => this.openResultsOrHighlightNext(results),
                },
            };
            const action = actions[event.keyCode];
            if (action) {
                action.action();
                if (action.prevent) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            }
        };
        this.onHighlight = (result) => {
            this.setState({ highlightedResult: result });
        };
        this.onSelect = (result, ev) => {
            const { onSelect, clearOnSelect, replaceQueryOnSelect } = this.props;
            if (ev) {
                ev.preventDefault();
            }
            if (clearOnSelect || replaceQueryOnSelect) {
                this.clear(this.focus);
                if (this.hasRegisteredKeyEventStackCallback) {
                    this.hasRegisteredKeyEventStackCallback = false;
                    keyEventStack.deregister(this.keyEventStackCallback);
                }
                if (replaceQueryOnSelect) {
                    if (typeof result !== 'string')
                        throw new Error('Result is not a string');
                    this.setState({ query: result });
                }
            }
            else {
                this.focus();
            }
            onSelect(result);
        };
        this.focus = () => {
            if (this.inputNode) {
                this.inputNode.focus();
            }
        };
        this.showResultDropdown = () => {
            if (this.initialRender) {
                return;
            }
            const { query } = this.state;
            if (!query) {
                this.tryQuickSelections();
            }
            else {
                this.setState({
                    showResults: true,
                });
            }
        };
        this.debouncedSearch = debounce(500, query => {
            this.search(query);
        });
        this.onChange = (event) => {
            event.stopPropagation();
            const query = event.target.value;
            if (!query && !this.props.searchOnEmptyQuery) {
                if (this.hasRegisteredKeyEventStackCallback) {
                    this.hasRegisteredKeyEventStackCallback = false;
                    keyEventStack.deregister(this.modalStackCallback);
                }
                this.clear();
                return;
            }
            this.setState({ query });
            this.debouncedSearch(query);
        };
    }
    componentDidMount() {
        this.initialRender = false;
    }
    componentWillUnmount() {
        if (this.hasRegisteredKeyEventStackCallback) {
            keyEventStack.deregister(this.keyEventStackCallback);
        }
    }
    setResults(results, isQuickSelection = false) {
        if (!this.hasRegisteredKeyEventStackCallback && this.state.query !== '') {
            this.hasRegisteredKeyEventStackCallback = true;
            keyEventStack.register(this.keyEventStackCallback);
        }
        this.setState({ results, showResults: true, quickSelectMode: isQuickSelection });
    }
    updateCache(query, results) {
        const { list, dict } = this.cache;
        list.push(query);
        dict[query] = results;
        if (list.length > MAX_CACHE_SIZE) {
            const toDiscard = list.shift();
            delete dict[toDiscard];
        }
    }
    openResultsOrHighlightNext(results) {
        if (!this.state.showResults) {
            this.showResultDropdown();
        }
        else {
            this.highlightNext(results);
        }
    }
    highlightNext(results) {
        const { highlightedResult } = this.state;
        this.onHighlight(getNext(results, highlightedResult));
    }
    highlightPrevious(results) {
        const { highlightedResult } = this.state;
        this.onHighlight(getPrevious(results, highlightedResult));
    }
    clear(callback) {
        this.setState({
            query: '',
            highlightedResult: undefined,
            results: [],
            showResults: false,
        }, callback);
    }
    tryQuickSelections() {
        if (this.props.quickSelectItems.length) {
            this.setResults(this.props.quickSelectItems, true);
        }
    }
    search(query) {
        const cached = this.cache.dict[query];
        if (cached) {
            this.setResults(cached);
        }
        else {
            const { queryFn } = this.props;
            queryFn(query).then(results => {
                this.updateCache(query, results);
                if (this.state.query) {
                    this.setResults(results);
                }
            });
        }
    }
    render() {
        const { id, className, children, maxItemsShown, getKey, itemHeight, inputStyle, resultsStyle, resultsContainerStyle, placeholder, error, autoFocus, filterResults, nothingFoundText, onBlur, onFocus, quickSelectItems, groupQuickSelect, showQuickSelectArrow, disabled, } = this.props;
        const { query, showResults, results, highlightedResult, quickSelectMode } = this.state;
        const filteredResults = filterResults(results, query); // ! has default prop
        const shownResults = take(filteredResults, maxItemsShown);
        const notShownResultsCount = filteredResults.length - maxItemsShown; // ! has default prop
        const inputProps = {
            id,
            value: query,
            onChange: this.onChange,
            onKeyDown: (ev) => this.interceptKeyboardHandling(ev, shownResults),
            className: inputStyle,
            placeholder,
            error,
            autoFocus,
            onClick: this.showResultDropdown,
            onFocus: () => {
                if (onFocus) {
                    onFocus();
                }
                this.showResultDropdown();
            },
            onBlur: (ev) => {
                // !node when this is triggered, the node should be present
                if (!this.node.contains(ev.relatedTarget)) {
                    this.hideResults();
                }
                (onBlur || identity)(ev);
            },
            getRef: (inputNode) => (this.inputNode = inputNode),
            autoComplete: 'off',
            disabled: disabled,
        };
        const resultsProps = {
            results: shownResults,
            showResults,
            notShownResultsCount,
            highlightedResult,
            onSelect: this.onSelect,
            onHighlight: this.onHighlight,
            renderItem: children,
            getKey: getKey, // ! getKey is in default props
            itemHeight,
            resultsStyle,
            resultsContainerStyle,
            quickSelectMode,
            groupQuickSelect,
            nothingFoundText,
        };
        const ariaAnnouncementProps = {
            results: shownResults,
            notShownResultsCount,
            highlightedResult,
            renderItem: children,
            showResults,
        };
        return (_jsxs("div", { className: className, ref: node => (this.node = node), children: [showQuickSelectArrow && quickSelectItems.length ? (_jsxs("div", { className: styles.inputContainer, children: [_jsx(Input, Object.assign({}, inputProps)), _jsx("div", { className: styles.arrow, children: _jsx(Arrow, { dir: "DOWN" }) })] })) : (_jsx(Input, Object.assign({}, inputProps))), _jsx(Results, Object.assign({}, resultsProps)), _jsx(AriaAnnouncer, { children: _jsx(AriaAnnouncement, Object.assign({}, ariaAnnouncementProps)) })] }));
    }
}
Autocomplete.defaultProps = {
    filterResults: t => t,
    maxItemsShown: 7,
    clearOnSelect: true,
    replaceQueryOnSelect: false,
    searchOnEmptyQuery: false,
    getKey: (t, i) => i,
    quickSelectItems: [],
    showQuickSelectArrow: true,
    nothingFoundText: 'Nothing found',
    disabled: false,
};
Autocomplete.propTypes = {
    id: PropTypes.string,
    queryFn: PropTypes.func.isRequired, // (query) => Promise<T[]>
    filterResults: PropTypes.func, // (T[]) => T[]
    onSelect: PropTypes.func.isRequired, // (T) => void
    clearOnSelect: PropTypes.bool,
    replaceQueryOnSelect: PropTypes.bool,
    searchOnEmptyQuery: PropTypes.bool,
    maxItemsShown: PropTypes.number,
    children: PropTypes.func.isRequired, // (T, isSelected: boolean) => Component
    getKey: PropTypes.func, // (T, i) => key
    itemHeight: PropTypes.number,
    quickSelectItems: PropTypes.array, // T[] - will immediately pop up the completion menu, before you start typing
    groupQuickSelect: PropTypes.func, // (T[]) => { label: string | undefined, results: T[] }[]
    showQuickSelectArrow: PropTypes.bool,
    query: PropTypes.string,
    nothingFoundText: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    inputStyle: PropTypes.string,
    resultsStyle: PropTypes.string,
    resultsContainerStyle: PropTypes.string,
    className: PropTypes.string,
    autoFocus: PropTypes.bool,
};
export default Autocomplete;
function Results({ results, showResults, notShownResultsCount, highlightedResult, onSelect, onHighlight, renderItem, getKey, itemHeight, resultsStyle, resultsContainerStyle, quickSelectMode, groupQuickSelect, nothingFoundText, }) {
    const resultsClasses = classNames(styles.results, resultsStyle, {
        [styles.results_empty]: !(results.length && showResults),
    });
    const containerClasses = classNames(styles.results_container, resultsContainerStyle);
    const resultGroups = quickSelectMode && groupQuickSelect ? groupQuickSelect(results) : [{ results: results, key: 'default' }];
    const resultsInnerProps = {
        showResults,
        highlightedResult,
        onSelect,
        onHighlight,
        renderItem,
        getKey,
        itemHeight,
        quickSelectMode,
        nothingFoundText,
    };
    return (_jsx("div", { className: containerClasses, children: _jsxs("div", { className: resultsClasses, children: [_jsx("div", { className: styles.results_inner, children: resultGroups.map(resultGroup => (_jsxs(Fragment, { children: [resultGroup.label && _jsx("h3", { className: styles.resultGroupHeading, children: resultGroup.label }), _jsx(ResultsInner, Object.assign({}, resultsInnerProps, { results: resultGroup.results }))] }, resultGroup.key || resultGroup.label))) }), _jsx(MoreItems, { count: notShownResultsCount, show: showResults })] }) }));
}
function isEmptyItem(item) {
    return item === EMPTY_ITEM;
}
function ResultsInner({ results, showResults, highlightedResult, onSelect, onHighlight, renderItem, getKey: originalGetKey, itemHeight, quickSelectMode, nothingFoundText, }) {
    const getKey = prepareGetKey(originalGetKey);
    const render = (result, key) => {
        if (isEmptyItem(result)) {
            return nothingFoundText ? _jsx(NothingFound, { text: nothingFoundText, height: itemHeight }, "nothing") : null;
        }
        const isHighlighted = result === highlightedResult;
        const props = {
            onMouseEnter: () => onHighlight(result),
            onMouseDown: (ev) => onSelect(result, ev),
            key: key,
            className: styles.item,
        };
        return (_jsx(Button, Object.assign({ kind: "PLAIN" }, props, { tabIndex: -1, children: renderItem(result, isHighlighted) })));
    };
    const items = prepareItems(results, showResults, quickSelectMode);
    return _jsx(_Fragment, { children: items.map((item, i) => render(item, getKey(item, i))) });
}
function NothingFound({ height, text }) {
    const style = height ? { height } : EMPTY_STYLE;
    return (_jsx("div", { className: styles.nothing_found, style: style, children: text }));
}
function MoreItems({ count, show }) {
    if (count <= 0 || !show) {
        return null;
    }
    return _jsxs("div", { className: styles.more_items, children: ["and ", count, " more"] });
}
function AriaAnnouncement({ showResults, results, renderItem, highlightedResult, notShownResultsCount, }) {
    if (!showResults) {
        return null;
    }
    if (highlightedResult && includes(results, highlightedResult)) {
        return renderItem(highlightedResult);
    }
    return _jsx("div", { children: getResultDescription(results, notShownResultsCount) });
}
function getResultDescription(results, notShownResultsCount) {
    const hiddenResults = Math.max(0, notShownResultsCount) ? ` and ${notShownResultsCount} hidden results` : '';
    return `Found ${results.length} results${hiddenResults}. Use arrow keys to navigate.`;
}
function prepareItems(items, showResults, quickSelectMode) {
    if (!showResults) {
        return [];
    }
    if (!items.length && !quickSelectMode) {
        return [EMPTY_ITEM];
    }
    return items;
}
function prepareGetKey(getKey) {
    return (item, i) => {
        if (item === EMPTY_ITEM) {
            return 'empty';
        }
        return getKey(item, i);
    };
}
