import { curry, join, first, last, initial, map } from 'lodash/fp';
import { ifs, OTHERWISE } from '..//Ifs';
export const concat = curry((concatenator, strings) => {
    const join_ = join(`\xa0${concatenator} `);
    return ifs([0, () => ''], [1, first], [2, join_], [OTHERWISE, (_, xs) => join_([join(', ', initial(xs)) + ',', last(xs)])])(strings.length, strings);
});
export const concatMap = curry((concatenator, fn, strings) => concat(concatenator, map(fn, strings)));
export const concatAmp = concat('&');
export const concatAnd = concat('and');
export const concatMapAmp = concatMap('&');
export const concatMapAnd = concatMap('and');
const findLastCharI = (char, text) => {
    for (let i = text.length - 1; i >= 0; i -= 1) {
        if (text[i].match(char)) {
            return i;
        }
    }
    return -1;
};
export const truncate_ = curry((ellider, limit, text) => {
    if (text.length < limit) {
        return text;
    }
    const max = limit - ellider.length;
    const plain = text.slice(0, max);
    const lastWsI = findLastCharI(/[\s\u00A0]/, plain);
    return lastWsI === -1 ? `${plain}${ellider}` : `${plain.slice(0, lastWsI)}\xa0${ellider}`;
});
export const truncate = truncate_('');
export const truncateAndEllide = truncate_('…');
