import PropTypes from 'prop-types';
import styles from './style.m.less';
import { uniq } from 'lodash';
import { Parser } from 'htmlparser2';
export const toHighlight = t => `<rich-text-highlight class="${styles.standard}">${t}</rich-text-highlight>`;
function escapeRegExp(str) {
    // https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript
    return str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}
export const name = 'highlight';
export const propType = PropTypes.arrayOf(PropTypes.string);
export const defaultProp = [];
class Handler {
    constructor(appendFn, words) {
        this._append = appendFn;
        this._words = words;
        this._regex = new RegExp(`(${words.map(escapeRegExp).join('|')})+`, 'gi');
    }
    onopentag(tag, attrs) {
        this._append('<' + tag);
        for (var attr in attrs) {
            if (Object.prototype.hasOwnProperty.call(attrs, attr)) {
                this._append(` ${attr}="${attrs[attr]}"`);
            }
        }
        this._append('>');
    }
    onclosetag(tag) {
        this._append('</' + tag + '>');
    }
    ontext(text) {
        this._append(this.replace(text));
    }
    replace(str) {
        const out = str.replace(this._regex, toHighlight);
        this._regex.lastIndex = 0;
        return out;
    }
}
export const onParse = (content, words) => {
    if (!words || words.every(word => !word)) {
        return content;
    }
    words = uniq(words);
    let results = '';
    const parser = new Parser(new Handler(s => (results += s), words));
    parser.write(content);
    parser.end();
    return results;
};
