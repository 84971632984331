import { getCompanyOnboardingSettingsCount, getOnboardingSettings } from '../../../api/custom/CompanyOnboarding';
import { goToOnboardingSurvey } from '../GoTo';
export async function showOnboardingFormToFirstFiveUsers(currentUser) {
    if (currentUser.isImpersonated || currentUser.isGuest) {
        return;
    }
    if (currentUser.company.dateCreated < new Date('2024-05-10').getTime()) {
        return;
    }
    const onboardingSettings = await getCompanyOnboardingSettingsCount();
    if (onboardingSettings >= 5) {
        return;
    }
    const settings = await getOnboardingSettings();
    if (settings === '') {
        goToOnboardingSurvey();
    }
}
